import React, { useEffect, useState } from 'react';

import "./styles.css"

import ScrollTop from './../assets/scrollTop.png';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // clean up on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    // toggle the "show" class on the button
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`scroll-to-top ${isVisible ? "show" : ""}`} onClick={handleClick}>
      {/* <span>Scroll to top</span> */}
      {/* <img style={{width: "30px", height: "30px"}} src={ScrollTop} alt="UP!"/> */}
    <p className="scroll-to-top-text">To top</p>
    </div>
  );
}

export default ScrollToTopButton;