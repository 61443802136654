import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Snackbar } from '@material-ui/core/';
import { useDispatch } from 'react-redux';

import { commentPost, deleteComment, flagCommentPost,likeCommentPost } from '../../../actions/posts';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from './styles';

import DeleteIcon from './assets/bin.png';

import { ReactComponent as WhiteHeartIcon } from './SVG/white-heart.svg';
import { ReactComponent as OrangeHeartIcon } from './SVG/orange-heart.svg';

import { ReactComponent as WhiteFlagIcon } from './SVG/white-flag.svg';
import { ReactComponent as OrangeFlagIcon } from './SVG/orange-flag.svg';

import Dots from './assets/dots.png';

import { Link } from "react-router-dom"


const PollCommentSection = ({ post }) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const [comments, setComments] = useState(post?.comments);
  const classes = useStyles();
  const commentsRef = useRef();


  const [open, setOpen] = useState(false);

// LIKES:
const [likes, setLikes] = useState(post?.comments?.likes);
const userId = user?.user?.result?._id;
const hasLikedPost = post?.comments?.likes?.find((like) => like === userId);
const [hasLiked, setHasLiked] = useState(false);

//   DELETED:
const [isDeleted, setIsDeleted] = useState(false);



const [hasFlagged, setHasFlagged] = useState(false);
const hasFlaggedPost = post?.arguments?.flags?.find((flag) => flag === userId);
const [flags, setFlags] = useState(false);




  const newComment = {
    pseudoname: user?.result?.pseudoname,
    comment: comment,
    likes: 0,
    creator: user?.result?._id,
    date: new Date(),
  };

  const handleComment = async () => {
    // const newComments = await dispatch(commentPost(`${user?.result?.name}: ${comment}`, post._id));
    const newComments = await dispatch(commentPost(post._id, newComment));
    setComment('');
    setComments(newComments);

    commentsRef.current.scrollIntoView({ behavior: 'smooth' });
  };



  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'just now';
    }
    const now = new Date();
    const diffMs = now - date;
  
    if (diffMs < 60000) {
      return 'just now';
    } else if (diffMs < 3600000) {
      const diffMins = Math.floor(diffMs / 60000);
      return `${diffMins} min${diffMins === 1 ? '' : 's'} ago`;
    } else if (diffMs < 86400000) {
      const diffHrs = Math.floor(diffMs / 3600000);
      return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
    } else {
      const diffDays = Math.floor(diffMs / 86400000);
      return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
    }
  }

    // LIKE
    const handleLikeComment = async (commentId, comment) => {
        await dispatch(likeCommentPost(post._id, commentId));
    
        if (hasLikedPost) {
          setLikes(comment.likes.filter((id) => id !== userId));
          setHasLiked(false);
        } else {
          setHasLiked(true);
        }
      };
    
      const LikeCommentPost = ({ comment }) => {
        const userId = user?.result?._id;
        const [hasLiked, setHasLiked] = useState(comment.likes.includes(userId));
        const [likedComment, setLikedComment] = useState(false);

        const handleIconClick = () => {
          setLikedComment(!likedComment); // Toggle the liked state
          handleLike(comment._id); // Call the original handleLike function
        };
      
        const handleLike = async (commentId) => {
          await dispatch(likeCommentPost(post._id, commentId));
      
          if (hasLiked) {
            setHasLiked(false);
            comment.likes = comment.likes.filter((like) => like !== userId);
          } else {
            setHasLiked(true);
            comment.likes.push(userId);
          }
        };
      
        return (
          <>
            {hasLiked ? (
            <>
            <div>
            <OrangeHeartIcon onClick={() => handleLike(comment._id)} />
            {/* <div className={classes.likeText} onClick={() => handleLike(comment._id)}>Liked</div>
            {comment.likesTotal !== 0 && (
            <p className={classes.likesTotalText}>{comment.likesTotal}</p>
            )} */}
            </div>
            </>
              // <>
              // <OrangeHeartIcon onClick={() => handleLike(comment._id)} />
              // <p className={classes.likesTotalText}>{comment.likesTotal}</p>
              // </>
            ) : (
              <>
              {/* <WhiteHeartIcon onClick={() => handleLike(comment._id)} /> */}
              <div onClick={() => {
            handleIconClick();
            handleLike(comment._id);
          }}>
            {likedComment ? <OrangeHeartIcon /> : <WhiteHeartIcon />}
          </div>
              </>
            )}
          </>
        );
      };


      // FLAG:
  const handleFlag = async (commentId, comment) => {
    await dispatch(flagCommentPost(post._id, commentId));

    if (hasFlaggedPost) {
      setFlags(comment.flags.filter((id) => id !== userId));
      setHasFlagged(false);
    } else {
      setHasFlagged(true);
    }
  };


  const Flag = ({ comment }) => {
    const userId = user?.result?._id;
    const [hasFlagged, setHasFlagged] = useState(comment.flags.includes(userId));
  
    const handleFlag = async (commentId) => {
      await dispatch(flagCommentPost(post._id, commentId));
  
      if (hasFlagged) {
        setHasFlagged(false);
        comment.flags = comment.flags.filter((flag) => flag !== userId);
      } else {
        setHasFlagged(true);
        comment.flags.push(userId);
      }
    };
  
    return (
      <>
        {hasFlagged ? (
          <OrangeFlagIcon onClick={() => handleFlag(comment._id)} />
        ) : (
          <WhiteFlagIcon onClick={() => handleFlag(comment._id)} />
        )}
      </>
    );
  };


  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(post._id, commentId));
    setIsDeleted(true);
  };

  useEffect(() => {
    if (isDeleted) {
      setComments(post.comments);
      setIsDeleted(false);
    }
  }, [isDeleted, post.comments]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
<div className={classes.commentsContainer}>
<div className={classes.addCommentsInnerContainer}>
<p className={classes.selectCommentContainerHeader}> Discussion </p>
</div>
<div className="selectCommentDashboard">
</div>
</div>

{post.arguments?.length > 0 ? (
<div className={classes.addCommentsContainer}>

  {post.comments.sort((a, b) => b.likesTotal - a.likesTotal)
  .filter((comment) => comment.flagsTotal < 30)
  .map((comment, index) => (
  <>

  <div className={classes.argumentCommentStyle} key={index}>
  <div className={classes.arguemntStyleAlign}>
  <p className={classes.commentUsernameStyle}>{comment.pseudoname}</p>
  <p className={classes.commentDateTextStyle} key={index}>{getTimeAgo(comment.createdAt)}</p>
  </div>
  {/* <p className={classes.commentTextStyle} key={index}>{comment.comment}</p> */}

  <div className={classes.topArgumentSpread}>
      {comment.comment.split('\n\n').map((paragraph, index) => (
  <div className={classes.commentTextStyle} key={index}>
    <p className={classes.commentTextStyle}>{paragraph}</p>
  </div>
))}
  </div>

  <div ref={commentsRef} />

  <div className="argumentSelectButtonRightAlign">


        {/* <Button size="small" color="primary" 
            onClick={() => handleFlag(comment._id)}>
            <Flag comment={comment} />
        </Button> */}

        <Button size="small" color="primary" 
            onClick={() => handleLikeComment(comment._id)}>
            <LikeCommentPost comment={comment} />
        </Button>


{user?.result?._id === comment.creator && (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
    <Button
      onClick={(clickEvent) => {
        handleDeleteComment(comment._id);
        clickEvent.target.style.display = 'none'; // hide the DeleteIcon image
      }}
    >
      {/* <p className={classes.flagText}>Delete</p> */}
      <img className={classes.resonateButtonBin} src={DeleteIcon} alt="delete" />
    </Button>
    </div>
)}





</div>
             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Comment deleted – it will no longer be visible once you refresh the page
                </Alert>
              </Snackbar>
  </div>


</>

))}


          
        <div ref={commentsRef} />
        </div>

        ) : (
          <div className={classes.addCommentsContainer}>
        {/* <p className={classes.addCommentsNoneYet}>No comments yet</p> */}
        <img style={{margin: "0px auto", display: "flex", justifyContent: "center", width: "22px"}} src={Dots} alt=""/>
        </div>
        
        )}



        {/* ADD COMMENT SECTION: */}
        <div style={{ width: '100%', margin: "0px auto"}}>


  {user?.result?.email ? (
    <>
        {/* <p className={classes.addCommentHeaderTop}>Add your comment:</p> */}
        <div className={classes.addCommentAlign}>
        <TextField fullWidth placeholder='Write your comment here' multiline variant="standard" value={comment} onChange={(e) => setComment(e.target.value)}   InputProps={{ style: { fontFamily: "Inconsolata", fontSize: "1rem" } }} />
        <Button style={{ fontFamily: "Open Sans", fontSize: "0.7rem", fontWeight: 700, backgroundImage: "radial-gradient(circle, #5C5A58, #423F3E, #3D3B3C)", color: "#e3e0dc", borderRadius: "3px", width: "90px", margin: "15px auto 0px"  }} fullWidth disabled={!comment.length} variant="contained" onClick={handleComment}>
          Comment
        </Button>
        </div>
        </>
  ) : (
 
    <Link style={{textDecoration: "none"}} to="/auth">
              <p className={classes.addCommentHeaderTop}>Add your comment:</p>
        <div className={classes.addCommentAlign}>
  
          <TextField fullWidth multiline variant="standard" value={comment} onChange={(e) => setComment(e.target.value)}   InputProps={{ style: { fontFamily: "Inconsolata", fontSize: "0.9rem" } }} />
          <Button style={{ fontFamily: "Open Sans", fontSize: "0.7rem", fontWeight: 700, backgroundImage: "radial-gradient(circle, #5C5A58, #423F3E, #3D3B3C)", color: "#e3e0dc", borderRadius: "3px", width: "90px", margin: "15px auto 0px"  }} fullWidth disabled={!comment.length} variant="contained" onClick={handleComment}>
            Comment
          </Button>
          </div>
    </Link>
  )}


      </div>
    </div>
  );
};

export default PollCommentSection;