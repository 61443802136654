// FUNCTION that acceepts the STATE and accepts the ACTION: do something based on the parameters set!

import { FETCH_ALL, FETCH_POST, CREATE, UPDATE, DELETE, LIKE, DISLIKE, LIKE_ARGUMENT, LIKE_COMMENT, FETCH_BY_SEARCH, START_LOADING, END_LOADING, COMMENT, ADD_ARGUMENT, FLAG, FLAG_COMMENT, DELETE_ARGUMENT, DELETE_COMMENT } from '../constants/actionTypes';


export default (state = {isLoading: true, posts: [], authDatas: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      }
      case FETCH_BY_SEARCH:
        return { ...state, posts: action.payload.data };
        case FETCH_POST:
          return { ...state, post: action.payload };
        case LIKE:
          return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
          case DISLIKE:
            return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
            case FLAG:
              return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
    


              

            case LIKE_ARGUMENT:
              return {
                ...state,
                posts: state.posts.map((post) =>
                  post._id === action.payload._id
                    ? {
                        ...post,
                        arguments: post.arguments.map((argument) =>
                          argument._id === action.payload.argumentId // use argument._id instead of argument.argumentId
                            ? { ...argument, likesTotal: action.payload.likesTotal, likes: action.payload.likes }
                            : argument
                        ),
                      }
                    : post
                ),
              };


              // case FLAG_COMMENT:
              //   return {
              //     ...state,
              //     posts: state.posts.map((post) =>
              //       post._id === action.payload._id
              //         ? {
              //             ...post,
              //             arguments: post.arguments.map((argument) =>
              //               argument._id === action.payload.argumentId // use argument._id instead of argument.argumentId
              //                 ? { ...argument, flagsTotal: action.payload.flagsTotal, flags: action.payload.flags }
              //                 : argument
              //             ),
              //           }
              //         : post
              //     ),
              //   };
            


              case DELETE_ARGUMENT:
                return {
    ...state,
    posts: state.posts.map((post) =>
      post._id === action.payload._id
        ? {
            ...post,
            arguments: post.arguments.filter((argument) => argument._id !== action.payload.argumentId)
          }
        : post
    )
  };



  case LIKE_COMMENT:
    return {
      ...state,
      posts: state.posts.map((post) =>
        post._id === action.payload.postId
          ? {
              ...post,
              comments: post.comments.map((comment) =>
                comment._id === action.payload.commentId
                  ? { ...comment, likesTotal: action.payload.likesTotal, likes: action.payload.likes }
                  : comment
              ),
            }
          : post
      ),
    };


      case FLAG_COMMENT:
        return {
          ...state,
          posts: state.posts.map((post) =>
            post._id === action.payload.postId
              ? {
                  ...post,
                  comments: post.comments.map((comment) =>
                    comment._id === action.payload.commentId
                      ? { ...comment, flagsTotal: action.payload.flagsTotal, flags: action.payload.flags }
                      : comment
                  ),
                }
              : post
          ),
        };



    
              case DELETE_COMMENT:
                return {
    ...state,
    posts: state.posts.map((post) =>
      post._id === action.payload._id
        ? {
            ...post,
            comments: post.comments.filter((comment) => comment._id !== action.payload.commentId)
          }
        : post
    )
  };

            


        case COMMENT:
          return {
            ...state,
            posts: state.posts.map((post) => {
              if (post._id === action.payload._id) {
                return action.payload;
              }
              return post;
            }),
          };

          

          case ADD_ARGUMENT:
            return {
              ...state,
              posts: state.posts.map((post) => {
                if (post._id === action.payload._id) {
                  return action.payload;
                }
                return post;
              }),
            };


        case CREATE:
          return { ...state, posts: [...state.posts, action.payload] };
        case UPDATE:
          return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
        case DELETE:
          return { ...state, posts: state.posts.filter((post) => post._id !== action.payload) };

        default:
      return state;
  }
};





// import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE } from '../constants/actionTypes';


// export default (posts = [], action) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return action.payload;
//         case CREATE: 
//             return [...posts, action.payload];
//         case UPDATE: 
//         case LIKE: 
//             return posts.map((post) => post._id === action.payload._id ? action.payload: post);
//         case DELETE: 
//             return posts.filter((post) => post._id !== action.payload);
//     default:
//        return posts;
//     }
// }