import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: 'center',
  },

  // NEW POST ALIGNMENT, removed GRID:
  postGrid: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    // margin: "0px auto"
  },

  errorText: {
    marginTop: '10px',
    padding: '12px 14px 0px 8px',
    marginBottom: 0,
    textAlign: "center",
    fontFamily: 'Inconsolata',
    color: "#3D3B3C",
    fontSize: "20px",
    fontWeight: 700
  },


  errorText2: {
    marginTop: '10px',
    padding: '12px 14px 0px 8px',
    marginBottom: 0,
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#f66e4b",
    fontSize: "20px",
    fontWeight: 700
  },

  noPostNotification: {
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#f66e4b",
    fontSize: "18px",
    fontWeight: 700
  },

  noPostNotification2: {
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#f66e4b",
    fontSize: "18px",
    fontWeight: 700
  },

  scrollToTop: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    cursor: 'pointer',
    display: 'none', /* hide initially */
  }


}));