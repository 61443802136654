import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({

  pollContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: "1px solid #e6e6e6",
    borderRadius: '30px',
    height: "auto",
    width: "75vw",
    margin: "30px",
    background: "#fff",
    padding: "15px 5px 30px",
  /* box-shadow:  5px -5px 16px #e6e6e6, -5px 5px 16px #ffffff; */
  },

  pollHeaderStatement: {
    fontFamily: "Orelega One",
    color: "#000080",
    fontSize: "22px",
    textAlign: "center",
    // maxWidth: "700px",
    margin: "20px 20px 5px",
    fontWeight: 500
  },

  pollVoteContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0px",
    padding: "0px"
  },


  userNotActionedStyle: {
    fontSize: "1.1rem",
    fontWeight: 700,
    fontFamily: "Inconsolata",
    color: "#3D3B3C",
  },

  userActionedStyle: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Urbanist",
    color: "#000080"
  },

  pollVTextStyle: {
    fontFamily: "Inconsolata",
    // color: "#838383",
    // color: "#000",
    fontWeight: 700
  },

  pollVoteSilence: {
    width: "90px",
    margin: "0px",
    cursor: "pointer"
  },


  pollVoteAmplifyDetails: {
    width: "25px",
    height: "25px",
    margin: "0px",
    cursor: "pointer"
  },

  topArgumentContainer: {
    // borderTop: "1px solid #3D3B3C",
    // borderBottom: "1px solid #3D3B3C",
    margin: "0px 0px 20px",
    paddingBottom: "8px",

  },


  topArgumentHeader: {
    display: "flex",
    justifyContent: "left",
    aligItem: "center",
    // borderBottom: "1px solid rgba(61, 59, 60, 0.5)",
    borderBottom: "1px solid #ccc",
    // fontFamily: "Inconsolata",
    fontFamily: "Open Sans, sans-serif",
    // color: "#3D3B3C",
    fontWeight: 700,
    fontSize: "0.9rem",
    letterSpacing: "0px",
    paddingBottom: "6px",
    margin: "10px 0px",
    textTransform: "none",

  },

  noArgumentsNotification: {
    fontSize: "0.7rem",
    fontFamily: "Urbanist",
    color: "#3D3B3C",
    textAlign: "center"
  },


// MEDIA RESPONSIVE:
  argumentStyle: {
    display: "flex",
    flexDirection: "row",
    aligItem: "center",
    margin: "0px 0px",
    borderBottom: "1px solid #3D3B3C",
    // justifyContent: "space-between",
  },


  


  argumentCommentStyle: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    margin: "0px 5px",
    borderBottom: "0.5px solid #3D3B3C",
    textAlign: "left"
  },

  arguemntStyleAlign: {
    display: "flex",
    flexDirection: "row"
  },

  argumentStylePositionAndDate: {
    display: "flex",
    flexDirection: "row",

  },


  topArgumentStylePosition: {
    fontFamily: "Inconsolata",
    fontSize: "1rem",
    margin: "0px",
    padding: "0px 8px",
    letterSpacing: "0.25px",
    // border: "2px solid #fe9981",
    // borderRadius: "0px 10px 10px 0px",
    color: "#3D3B3C",
    // backgroundImage: "linear-gradient(to top, #fe9981, #fe8b6e)",
    // marginRight: "0px",
    // marginBottom: "4px",
    // padding: "2px 0px",
    textTransform: "capitalize",
    fontWeight: 900,
    textAlign: "left",
    // height: "100%",
    // minWidth: "70px",
    // maxHeight: "15px",
  },


  argumentStylePosition: {
    fontFamily: "Inconsolata",
    fontSize: "12px",
    // border: "2px solid #FE876B",
    borderRadius: "0px 10px 10px 0px",
    color: "#3D3B3C",
    marginRight: "10px",
    marginBottom: "4px",
    padding: "2px 0px",
    textTransform: "capitalize",
    fontWeight: 900,
    textAlign: "left",
    height: "100%",
    minWidth: "70px",
    maxHeight: "15px"
  },

  argumentStyleDateTextStyle: {
    fontFamily: "Inconsolata",
    fontSize: "13px",
    margin: "2px 0px 4px",
    textAlign: "left"
  },

  argumentStyleDateTextStyle2: {
    fontFamily: "Inconsolata",
    fontSize: "13px",
    margin: "4px 0px 4px",
    textAlign: "left"
  },

  argumentScrollDownText: {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "11px",
    fontWeight: 900,
    margin: "auto",
    padding: "7px 0px",
    textAlign: "center",
    textTransform: "uppercase"
  },

  argumentStyleStatement: {
    fontFamily: "Open Sans",
    fontSize: "0.8rem",
    // color: "#000",
    textAlign: "left",
    // textTransform: "capitalize",
    padding: "0px 5px",
    lineHeight: "1.1rem",
    fontWeight: 500,
        // ensure horizonatal and vertical levelness
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        height: "100%"
  },



  argumentSelectStyleStatement: {
    fontFamily: "Open Sans",
    fontSize: "0.8rem",
    color: "#3D3B3C",
    textAlign: "left",
    padding: "0px",
    lineHeight: "1.2rem",
        height: "100%",
        width: "100%",
        fontWeight: 500
  },




  // selectArgumentContainer: {
  //   border: "2.5px solid #FE876B",
  //   borderRadius: '2px 2px 2px 2px',
  //   margin: "10px 20px 20px",
  //   maxHeight: "40vh",
  //   overflow: "auto",
  //   paddingBottom: "10px"
  // },

  selectArgumentContainerAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "10px 0px 0px",
    borderBottom: "0.5px solid rgba(61, 59, 60, 0.3)",
  },

  selectDetailsArgumentContainer: {
    border: "2.5px solid #FE876B",
    borderRadius: '2px 2px 2px 2px',
    margin: "10px 20px 20px",
    height: "auto",
    maxHeight: "100vh",
    overflow: "auto",
    paddingBottom: "10px"
  },

  argumentSelectButtonRightAlign: {
    marginLeft: "auto",
  },


  selectArgumentDashboard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // borderBottom: "2.5px solid #FE876B",
    
  },

  selectArgumentContainerHeader: {
    display: "flex",
    justifyContent: "left",
    aligItem: "left",
    // borderBottom: "0.5px solid #e3e0dc",
    fontFamily: "Open Sans, sans-serif",
    // color: "#3D3B3C",
    fontWeight: 700,
    fontSize: "0.9rem",
    letterSpacing: "0px",
    paddingBottom: "4px",
    margin: "1px 0px",
    textTransform: "none",
  },

  sortByText: {
    fontFamily: "Inconsolata",
    color: "#3D3B3C",
    fontSize: "12px",
    fontWeight: 500,
    paddingLeft: "20px",
    textTransform: "uppercase",
  },

  selectArgumentDashboardButton: {
    fontFamily: "Open Sans",
    color: "#3D3B3C",
    border: "0px solid rgba(61, 59, 60, 0.5)",
    margin: "5px 0px 5px",
    padding: "0px 6px",
    fontWeight: 700,
    fontSize: "0.6rem",
    // minWidth: "50px",
    letterSpacing: "0px",
    borderRadius: "2.5px",
    letterSpacing: "0px"
  },

  selectCommentDashboardButton: {
    fontFamily: "Urbanist",
    color: "#fd876b",
    border: "1.5px solid #fd876b",
    margin: "8px",
    padding: "0px 4px",
    fontWeight: 500,
    fontSize: "9px",
    minWidth: "70px"
  },


  addArgumentDashboardButton: {
    fontFamily: "Open Sans",
    color: "#e3e0dc",
    // border: "0.5px solid #3D3B3C",
    margin: "0px 2px 1px",
    padding: "1px 8px",
    fontWeight: 700,
    fontSize: "0.58rem",
    letterSpacing: "0.2px",
    // backgroundImage: "linear-gradient(to top, rgba(254, 153, 56, 0.9), rgba(254, 147, 55, 0.9), rgba(254, 141, 55, 0.9), rgba(254, 135, 55, 0.9), rgba(254, 129, 55, 0.9), rgba(254, 124, 55, 0.9), rgba(254, 119, 55, 0.9), rgba(254, 114, 56, 0.9), rgba(254, 109, 56, 0.9), rgba(254, 104, 56, 0.9), rgba(254, 99, 56, 0.9), rgba(254, 94, 56, 0.9))"
    backgroundColor: "rgba(61, 59, 60, 0.9)",
    borderRadius: "3px"
  },


  argumentScrollDownContainer: {
    backgroundColor: "#FE876B",
    display: "flex",
    flexDirection: "row",
    maxWidth: "125px",
    margin: "15px auto 0px",
    borderRadius: "40px"
  },

  argumentScrollDownText: {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "11px",
    fontWeight: 900,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "11px",
    marginRight: "0px",
    textAlign: "center",
    textTransform: "uppercase"
  },

  argumentScrollDownImage: {
    width: "27px",
    margin: "auto",
    padding: "0px 5px"
  },



pollHeaderStatementDelete: {
  fontFamily: "Urbanist",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  /* max-width: 700px; */
  margin: "20px 50px",
  fontWeight: 500,
  wordWrap: "break-word"
},


  readMoreButtonArgumentDelete: {
    fontFamily: "Urbanist",
    color: "#fff",
    fontSize: "10px",
    textAlign: "center",
    /* max-width: 700px; */
    margin: "20px 5px 5px",
    fontWeight: 900,
    textTransform: "uppercase"
  },

  argumentSelectContainer: {
    // maxHeight: "50vh",
    // width: "auto",
    // overflow: "auto",
    display: "flex",
    flexDirection: "column",
    // overflow: "scroll"
  },

  argumentDetailsSelectContainer: {
    // maxHeight: "80vh",
    // overflow: "auto",
    margin: 0,
    padding: 0
  },


  argumentSelectStyle: {
    display: "flex",
    margin: "0px 15px 5px",
    borderBottom: "0.2px solid rgba(61, 59, 60, 0.3)",
    // justifyContent: "space-between",
    // alignItems: "center",
    justifyContent: "column"
  },

  argumentResonateButton: {
    display: "flex",
    justifyContent: "flex-end",
  },

  resonateButton: {
    width: "25px",
    height: "25px",
    margin: "0px",
    cursor: "pointer",
  },

  resonateButtonBin: {
    width: "17px",
    height: "17px",
    margin: "0px",
    cursor: "pointer",
  },

  resonateButtonFlag: {
    width: "18px",
    height: "18px",
    margin: "0px",
    cursor: "pointer",
  },


  resonateButtonHeart: {
    width: "19px",
    height: "19px",
    margin: "0px",
    cursor: "pointer",
  },

  likesTotalText: {
    fontFamily: "Urbanist",
    fontSize: "12px",
    marginLeft: "5px",
    color: "#fd876b"
  },


  postOptionDashboard: {    
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: '3px',
    margin: "0px 0px 8px",
    padding: "10px 0px",
    maxHeight: "25vh",
    overflow: "auto",
    // backgroundColor: "#e3e0dc",
    // border: "1px solid #3D3B3C",
    // border: "0.5px solid rgba(61, 59, 60, 0.3)"
  },

  postOptionDashboardAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  
  postOptionDashboardRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  
  postStyleDateTextStyle: {
    fontFamily: "Inconsolata",
    fontSize: "0.9rem",
    color: "#3D3B3C",
    margin: "0px 8px 5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },


  selectArgumentDashboardButtonWhiteDiscussion: {
    fontFamily: "Open Sans",
    color: "#e3e0dc",
    // border: "2px solid ##3D3B3C",
    margin: "1px 4px",
    padding: "2px 10px",
    fontWeight: 700,
    letterSpacing: "0px",
    fontSize: "0.58rem",
    // backgroundImage: "linear-gradient(to top, rgba(254, 153, 56, 0.9), rgba(254, 147, 55, 0.9), rgba(254, 141, 55, 0.9), rgba(254, 135, 55, 0.9), rgba(254, 129, 55, 0.9), rgba(254, 124, 55, 0.9), rgba(254, 119, 55, 0.9), rgba(254, 114, 56, 0.9), rgba(254, 109, 56, 0.9), rgba(254, 104, 56, 0.9), rgba(254, 99, 56, 0.9), rgba(254, 94, 56, 0.9))"
    backgroundColor: "rgba(61, 59, 60, 0.9)"
  },

selectArgumentDashboardButtonWhite: {
  fontFamily: "Inconsolata",
  color: "#e3e0dc",
  // border: "2px solid ##3D3B3C",
  margin: "2.5px 6px 4px",
  padding: "0px 9px",
  fontWeight: 900,
  letterSpacing: "0.8px",
  fontSize: "0.8rem",
  // backgroundImage: "linear-gradient(to top, rgba(254, 153, 56, 0.9), rgba(254, 147, 55, 0.9), rgba(254, 141, 55, 0.9), rgba(254, 135, 55, 0.9), rgba(254, 129, 55, 0.9), rgba(254, 124, 55, 0.9), rgba(254, 119, 55, 0.9), rgba(254, 114, 56, 0.9), rgba(254, 109, 56, 0.9), rgba(254, 104, 56, 0.9), rgba(254, 99, 56, 0.9), rgba(254, 94, 56, 0.9))"
  backgroundColor: "#3D3B3C"
},

selectArgumentDashboardButtonWhiteFlag: {
  fontFamily: "Open Sans",
  color: "#DEDBD7",
  // border: "2px solid #FFF",
  margin: "0px",
  padding: "0px 0px",
  fontWeight: 700,
  fontSize: "0.58rem",
  minWidth: "60px",
  cursor: "pointer"
},

addArgumentInnerContainer: {
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  margin: "0px 12px",
},

addArgumentPreHeaderPrompt: {
  color: "#FFF",
  fontSize: "0.9rem",
  fontWeight: 500,
  fontFamily: "Open Sans",
},

addArgumentHeader: {
  fontSize: "0.8rem",
  // fontFamily: "Inconsolata",
  fontFamily: "Open Sans",
  fontWeight: 500,
  margin: "10px",
  color: "#e3e0dc",
  wordBreak: "break-word", 

},

addArgumentAlign: {
  display: "flex",
  flexDirection: "column"
},

addCommentHeaderTop: {
  fontFamily: "Inconsolata",
  fontSize: "1rem",
  fontWeight: 500
},

addCommentAlign: {
  display: "flex",
  flexDirection: "column",
  margin: "10px 0px",
  padding: "0px"
},



// COMMENTS
  commentsContainer: {
    // borderLeft: "1px solid rgba(61, 59, 60, 0.03)",
    // borderTop: "1px solid rgba(61, 59, 60, 0.03)",
    // borderRight: "1px solid rgba(61, 59, 60, 0.03)",
    // borderBottom: "0px",
    borderRadius: '0px',
    margin: "10px 15px 0px",
    maxHeight: "50vh",
    overflow: "auto",
  },



  commentUsernameStyle: {
    fontFamily: "Inconsolata",
    fontSize: "13px",
    margin: "15px 7px 10px",
    fontWeight: 700,
    color: "#3D3B3C"
  },

  commentDateTextStyle: {
    fontFamily: "Inconsolata",
    fontSize: "11px",
    margin: "17px 3px 10px",
  },


  commentTextStyle: {
    fontFamily: "Open Sans",
    fontSize: "0.8rem",
    margin: "0px 0px 5px",
    padding: "0px 5px",
    lineHeight: "1.3em",
    fontWeight: 0,
  },


  addCommentsContainer: {     
    // border: "0.5px solid #3D3B3C",
    borderTop: "0px",
    borderRadius: '0px ',
    margin: "0px 15px 20px",
    padding: "0px 5px 10px",
    maxHeight: "30vh",
    overflow: "auto",
},


addCommentsInnerContainer: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  // justifyContent: "space-between",
  // margin: "0px 12px",
  // borderBottom: "2.5px solid #FE6E4B"
},

addCommentHeader: {
  fontSize: "18px",
  fontFamily: "Urbanist",
  fontWeight: 700,
  // marginLeft: "10px",
  color: "#fd876b",
  textAlign: "center"

},


addCommentAlign: {
  display: "flex",
  flexDirection: "column",
  margin: "20px"
},

viewModeCommentNote: {
  fontSize: "12px",
  fontFamily: "Urbanist",
  fontWeight: 500,
  textAlign: "center",
  color: "#3D3B3C",
  margin: "0px 10px 20px"
},

addCommentsNoneYet: {
  textAlign: "center",
  fontFamily: "Urbanist",
  color: "#3D3B3C",
  fontSize: "0.7rem"
}











});



// import { makeStyles } from '@material-ui/core/styles';

// export default makeStyles({
//   media: {
//     height: 0,
//     paddingTop: '56.25%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     backgroundBlendMode: 'darken',
//   },
//   border: {
//     border: 'solid',
//   },
//   fullHeightCard: {
//     height: '100%',
//   },
//   card: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     borderRadius: '15px',
//     height: '100%',
//     position: 'relative',
//   },
//   overlay: {
//     position: 'absolute',
//     top: '20px',
//     left: '20px',
//     color: 'white',
//   },
//   overlay2: {
//     position: 'absolute',
//     top: '20px',
//     right: '20px',
//     color: 'white',
//   },
//   grid: {
//     display: 'flex',
//   },
//   details: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     margin: '20px',
//   },
//   title: {
//     padding: '0 16px',
//   },
//   cardActions: {
//     padding: '0 16px 8px 16px',
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
// });

