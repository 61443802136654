import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  root: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(1),
      primary: "#000",
      margin: "10px 0px"

    },
  },
  paper: {
    padding: " 5px 10px",
    fontFamily: 'Urbanist',
    borderRadius: "30px",
    boxShadow:  "5px 5px 16px #d9d9d9, -5px -5px 16px #ffffff;",
    border: "1.5px solid #e6e6e6",
    width: "80vw",
    margin: "0px auto"
  },

  paperSignIn: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    borderRadius: "20px",
    // marginBottom: "80px",
    border: "7px solid #FE876B",
    maxWidth: "40vw",
    margin: "50px auto 80px",
    backgroundColor: "#FE876B"
  },


  paperBorder: {
    margin: "5px 0px",
    padding: "10px",

  },

  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  fileInput: {
    width: '97%',
    margin: '10px 0',
    display: "flex",
    justifyContent: "center",
    fontFamily: "Urbanist",
    color: "#FE5E38"
  },
  buttonSubmit: {
    marginBottom: 10,
  },


  appBarSearch: {
    borderRadius: 4,
    marginBottom: '1rem',
    display: 'flex',
    padding: '16px',
  },
  pagination: {
    borderRadius: 4,
    marginTop: '1rem',
    padding: '16px',
  },
  gridContainer: {
    // [theme.breakpoints.down('xs')]: {
    //   flexDirection: 'column-reverse',
    // },

// Added for JEB.
searchButton: {
  backgroundColor: '#FE5E38',
  color: "#FFF",
  fontFamily: 'Urbanist',
  paddingTop: "20px"
}
  },


  csHeader: {
    fontFamily: 'Urbanist',
    fontSize: "25px",
    marginTop: "10px",
    marginBottom: "20px",
    color: "#FE5E38",
    fontWeight: 900,
    borderBottom: "4px solid #FE876B",
    padding: 2,
  },

  signInFlag: {
    textAlign: "center",
    fontSize: "23px",
    color: "#fff",
    // color: "#FE876B",
    fontFamily: "Urbanist",
    fontWeight: 500
  },


submissionContainer: {
  width: "80vw",
},

submissionContainer2: {
  display: "flex",
  flexDirection: "row",
  width: "80vw",
},

submissionHeader: {
  fontFamily: "Orelega One",
  color: "#5F5B6B",
  margin: "8px 10px",
  fontSize: "27px",
  alignSelf: "center"
},

submissionContainerButton: {
  display: "flex",
  flexDirection: "column",
  alignItems: "right",
  float: "right",
  // width: "20vw",
  // backgroundColor: "rgb(254, 110, 75)", 
  color: "#FFF", 
  margin: "20px 0px", 
  fontFamily: "Open Sans",
  fontWeight: 700,
},


submittedNoteContainer: {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "10vh" /* or any other height you want */
},


successNotificationContainer: {
  marginTop: "10vh",
},

successNotification: {
  textAlign: "center",
  fontFamily: 'Inconsolata',
  color: "#3D3B3C",
  fontSize: "20px",
  fontWeight: 700,
  // maxWidth: "500px"
},

errorNotificationContainer: {
  marginTop: "10vh",
},

errorNotification: {
  textAlign: "center",
  fontFamily: 'Open Sans',
  color: "#5F5B6B",
  fontSize: "1rem",
  fontWeight: 0,
  maxWidth: "600px"
},

errorNotificationLive: {
  textAlign: "center",
  fontFamily: 'Open Sans',
  color: "#5F5B6B",
  fontSize: "14px",
  fontWeight: 500,
  maxWidth: "400px"
},

backToExploreButton: {
  // marginTop: "20vh",
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  margin: "30px auto",
  backgroundImage: "radial-gradient(circle, #5b5956, #423F3E, #3D3B3C);",
  padding: "10px 10px",
  borderRadius: "5px",
  fontSize: "0.8rem",
  color: "#FFF",
  fontFamily: "Open Sans",
  fontWeight: 700,
  boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.3)",
  // position: "absolute"
},

backToExploreButtonText: {
  padding: "0px",
  margin: "0px 30px",
  fontFamily: "Open Sans",
},



  //AUTH BACKGROUND:

  backgroundImageStyle: {
    display: "flex",
    margin: "0px auto",
    opacity: "0.8",
    maxWidth: "100%"
},

authBackgroundImage: {
    position: 'relative',
    display: "flex",
    margin: "0px auto",
    height: "100vh"
},
}));