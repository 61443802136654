import React from 'react'
import { Link } from "react-router-dom"
import "./styles.css"

import UrbanDictionaryLogo from "./assets/UrbanDictionaryLogo.png"
import UrbanDictionaryDefinition from "./assets/UrbanDictionaryDefinition.png"

const LandingPage = () => {
  return (
    <div className="landingPageContainer">

<p className="landingPageTextHeader">Where <span className="landingPageSubText">thoughts</span>, <span className="landingPageSubText">issues</span>, or <span className="landingPageSubText">ideas</span> are put under the spotlight</p>

      {/* <div className="urbanDictionaryContainer">
      <img style={{width: "20vw", maxWidth: "150px"}} src={UrbanDictionaryLogo} alt=""/>
      <img style={{width: "100%"}}src={UrbanDictionaryDefinition} alt=""/>
      </div> */}


{/* 
      <p className="landingPageText">Upvote topics that you find interesting or deserve debate.</p>
      <p className="landingPageText">The top-voted topic of the last 7 days will have a live slot and can be debated on the <Link className="landingPageSubTextLink" to="/explore">current page</Link></p>

      <p className="landingPageText">Contribute with or support positions that resonate or teach you something new</p>

      <p className="landingPageText">Positions with the most support will earn spots in the Top Positions section. </p> */}

      {/* <p className="landingPageText">Here's to curiosity, respect and open-mindedness.</p> */}
    </div>
  )
}

export default LandingPage



// import React from 'react'
// import { Link } from "react-router-dom"
// import { Button } from '@material-ui/core/';
// import useStyles from './styles';
// import "./styles.css"


// // IMAGES
// // import ImageSection1 from "./../assets/imageSection1.png"
// import ImageSection2 from "./../assets/imageSection2.png"

// import ForCharacter from "./assets/NewF.png"
// import NeutralCharacter from "./assets/NewN.png"
// import AgainstCharacter from "./assets/NewA.png"

// import For from './assets/for.png';
// import Neutral from './assets/neutral.png';
// import Against from './assets/against.png';
// import Poll from './assets/poll.png';

// import TopTab1 from './assets/1.png';
// import TopTab2 from './assets/2.png';

// import Explore from './../assets/exploreButton2.png';
// import TopPosition from './assets/topPositions.png';


// import Step1IMG from "./assets/Step1IMG.png"
// import Step2IMG from "./assets/Step2IMG.png"


// // MOV / VIDEO:
// // import PostStatementVideo from './assets/PostStatement.mov';
// // import Position from './assets/Position.mov';



// const LandingPage = () => {
//   const classes = useStyles();
//   const user = JSON.parse(localStorage.getItem('profile'));



//   return (
//     <>
//     <div className="lp_section1">
//       <h1 className="lp_section1Header1">Think differently</h1>

//       <p className="lp_section1Header1Sub">Become the intellectual you are meant to be</p>

//       {/* <img className="lp_glassesSyted" src={Glasses} alt=""/> */}
//       <div className="lp_charactersContainer">
//       <img className="lp_glassesSyted" src={ForCharacter} alt=""/>
//       <img className="lp_glassesSyted2" src={NeutralCharacter} alt=""/>
//       <img className="lp_glassesSyted3" src={AgainstCharacter} alt=""/>

//       <img className="lp_glassesSyted2" src={ForCharacter} alt=""/>
//       <img className="lp_glassesSyted" src={NeutralCharacter} alt=""/>
//       <img className="lp_glassesSyted2" src={AgainstCharacter} alt=""/>

//       <img className="lp_glassesSyted3" src={ForCharacter} alt=""/>
//       <img className="lp_glassesSyted" src={NeutralCharacter} alt=""/>
//       <img className="lp_glassesSyted" src={AgainstCharacter} alt=""/>

//       </div>

//       {/* <span style={{fontFamily: "Orelega One", fontSize: "32px", fontWeight: 700}}>Syted</span> is */}
//       {/* <img className="lp_section2point5ImageStyle" src={ImageSection1} alt=""/> */}
   
//     </div>



//     <div className="lp_section2point5">

//     <h1 className="lp_header2point5Sub2">How sussed works</h1>

//     <div className="lp_sectionBreak">
//     <div className="lp_sectionBreak1">

//     <p className="lp_section2point5TextStyle">Post statements</p>
//       <p className="lp_section2point5TextStyleShort"> </p>
      
//       <p className="lp_section2point5TextStyleLong"> There are <span style={{fontWeight: 700}}>10 post slots</span> available every <span style={{fontWeight: 700}}>24 hours</span>.</p>
//       <p className="lp_section2point5TextStyleLong"> Users can engage by contirbuting their positions, support positions they like and upvote or downvote posts.</p>
//       <p className="lp_section2point5TextStyleLong"> — If a post is deleted by the author or overly downvoted by the community, a new post slot becomes available.</p>

    
//     </div>


    
//     <div className="lp_sectionBreak2">
//     <div className="lp_imageContainerStyle">
//       {/* <video src={PostStatementVideo} style={{borderRadius: "10px"}} autoPlay loop muted controls={false} width="550" height="350" /> */}
 



//   {/* <video
//   // src={PostStatementVideo}
//   src="https://syted-assets.s3.ap-northeast-1.amazonaws.com/PostStatement.mov"
//   className="video-container"
//   loop
//   autoPlay
//   muted
//   playsinline
//   controls={false}
// ></video> */}

// <img className="video-container" src={Step1IMG} alt=""/>

//     </div>
//     </div>
//     </div>



//     <div className="lp_sectionBreak">
//     <div className="lp_sectionBreak1PreHidden">
//     <div className="lp_imageContainerStyle">
//     {/* <video src={Position} style={{borderRadius: "10px"}} autoPlay loop muted controls={false} width="600" height="400" /> */}

//     {/* <video
//   // src={Position}
//   src="https://syted-assets.s3.ap-northeast-1.amazonaws.com/Position.mov"
//   className="video-container"
//   loop
//   autoPlay
//   muted
//   playsinline
//   controls={false}
// ></video> */}

// <img className="video-container" src={Step2IMG} alt=""/>

//     </div>
//     </div>


//     <div className="lp_sectionBreak2">
//     <p className="lp_section2point5TextStyle"> <span style={{fontWeight: 700}}>Contribute</span> with positions</p>
//     <p className="lp_section2point5TextStyleLong"> Users can contribute their positions to each post, choosing between:</p>
//     <p className="lp_positionOptions">— Agree</p>
//     <p className="lp_positionOptions">— Undecided</p>
//     <p className="lp_positionOptions">— Disagree</p>

//     <p className="lp_section2point5TextStyleLong"> After selecting their position, users will write persuasive arguments to support their chosen stance.</p>

//    <p className="lp_section2point5TextStyleLong"> After 7 days, posts will transition into <span style={{fontWeight: 700}}>view mode</span>, and users will no longer be able to interact with it.</p>
//     </div>

//     <div className="lp_sectionBreak1Hidden">
//     <div className="lp_imageContainerStyle">
//     {/* <video src={Position} style={{borderRadius: "10px"}} autoPlay loop muted controls={false} width="550" height="350" /> */}


//     {/* <video 
//    // src={Position}
//     src="https://syted-assets.s3.ap-northeast-1.amazonaws.com/Position.mov"
//     className="video-container"
//     loop
//     autoPlay
//     muted
//     playsinline
//     controls={false}
//   ></video> */}

// <img className="video-container" src={Step2IMG} alt=""/>

//     </div>
//     </div>

//     </div>




//     <div className="lp_sectionBreak">
//     <div className="lp_sectionBreak1">
      
//     <p className="lp_section2point5TextStyle"> <span style={{fontWeight: 700}}>Support</span> arguments</p>
//     <p className="lp_section2point5TextStyleLong"> The strongest arguments will move up in the rankings and be featured in the <span style={{fontWeight: 900}}>Top Positions</span> section.</p>
  
//     <p className="lp_section2point5TextStyleLong">  This is where the community has chosen the best opinions that are worth seeing.</p>

//     </div>

//     <div className="lp_sectionBreak2">
//     <div className="lp_imageContainerStyle">
//     {/* <img className="lp_section2point5ImageStyle" src={TopPosition} alt=""/> */}
//     <img className="video-container" src={TopPosition} alt=""/>
//     </div>
//     </div>
//     </div>


//     </div>



//     <div className="lp_section1">
//       <h1 className="lp_section1Header1"> Highlighting the Best </h1>
     
//       <p className="lp_section1Header1Sub2">Check out Syted's top posts in the <span style={{fontWeight: 900, borderBottom: "3px solid #FFF"}}>Top</span> tab. </p>
//       <p className="lp_section1Header1Sub22">They've earned their spot by getting the most upvotes. </p>



//     <div className="lp_topPostsContainer">
//        <img className="lp_topPostsImageStyle" src={TopTab1} alt=""/>
//        <img className="lp_topPostsImageStyleHide" src={TopTab2} alt=""/>
//        {/* <img className="lp_topPostsImageStyle" src={TopTab3} alt=""/> */}
//        </div>
   
//     </div>



//     <div className="lp_section2point5LightModeOnly">

  

//     {/* <p className="lp_section2point5TextStyle">Have a thought you want to explore?</p>
//     <p className="lp_section2point5TextStyle"> Post it as a statement and let the discussion begin.</p>
//     <p className="lp_section2point5TextStyleLong">Users contribute by submitting their argument and voting for top positions.</p> */}



//     {/* <p className="lp_section2point5TextStyleExample">Example below:</p> */}


//         <h1 className="lp_header1"> "Syted is the coolest sh*t ever?"</h1>


  
//         <div className={classes.lp_positionsGrid}>


//         <div className="lp_positionsGrid1">
//         <div className="lp_positionsGridContent1">
//         <img className="lp_postionJebber" src={ForCharacter} alt=""/>
//         <img className="lp_positionQuote" src={For} alt=""/>
//         </div>


//         <div className="lp_positionsGridContent2">
//         <img className="lp_postionJebber" src={NeutralCharacter} alt=""/>
//         <img className="lp_positionQuote"  src={Neutral} alt=""/>
//         </div>


//         <div className="lp_positionsGridContent3">
//         <img className="lp_postionJebber" src={AgainstCharacter} alt=""/>
//         <img className="lp_positionQuote" src={Against} alt=""/>
//         </div>
//         </div>

//         <div className="lp_positionsGrid2">
//           <div className="lp_positionPollImageContainer">
//           <img className="lp_positionPollImage" src={Poll} alt=""/>
//           </div>
//         </div>

   
//         </div>
        
//         <div style={{position: "relative"}}>

//         {user?.result ? (
//   <Link style={{textDecoration: "none"}} to="/explore">
//     <Button className={classes.exploreButton} style={{position: "absolute", top: "30%", left: "15vw", transform: "translate(-50%, -50%)"}}>
//       <p className={classes.exploreButtonText}>Explore now</p>
//     </Button>
//   </Link>
//           ) : (

//   <Link style={{textDecoration: "none"}} to="/">
//     <Button className={classes.exploreButton} style={{position: "absolute", top: "30%", left: "15vw", transform: "translate(-50%, -50%)"}}>
//       <p className={classes.exploreButtonText}>Explore now</p>
//     </Button>
//   </Link>
//   )}
//   {/* <img src={ImageSection2} alt="" className="imageSection2Style"/> */}
// </div>
//         </div>



//             {/* FOOTER START */}
//       <div className="footerSection">
//         <div className="footerResponsiveAlign">
//       <div className="footerOptionLogo">Syted</div>

//       <Link style={{textDecoration: "none"}} to="/contact">
//       <div className={classes.footerOption}>Contact us</div>
//       </Link>

//       {/* <Link style={{textDecoration: "none"}} to="/terms-and-conditions">
//       <div className={classes.footerOption}>Terms</div>
//       </Link>

//       <Link style={{textDecoration: "none"}} to="/privacy-policy">
//       <div className={classes.footerOption}>Privacy Policy</div>
//       </Link> */}
//       </div>
//       </div>
//       {/* FOOTER END */}

//       </>
//   )
// }

// export default LandingPage










