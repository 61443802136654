import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import App from './App';
import './index.css';
import './darkmode.css';

function Index() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  function toggleDarkMode() {
    setIsDarkMode(!isDarkMode);
  }

  const store = createStore(reducers, compose(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      {/* <div className={isDarkMode ? 'light-mode' : 'dark-mode'}>
        <div className="switch-button" onClick={toggleDarkMode}>
          {isDarkMode ? 'DARK MODE' : 'LIGHT MODE'}
        </div> */}
        <App />
      {/* </div> */}
    </Provider>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));




// import React from "react"
// import ReactDOM from "react-dom"
// import { Provider } from "react-redux"
// import "./index.css"

// import { legacy_createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';

// import { reducers } from './reducers';

// import App from "./App"

// const store = legacy_createStore(reducers, compose(applyMiddleware(thunk)));

// ReactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>,
// document.getElementById("root"))


// FULL TUTORIAL: https://www.youtube.com/watch?v=VsUzmlZfYNg