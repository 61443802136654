export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const DISLIKE = 'DISLIKE';
export const FLAG = 'FLAG';

export const LIKE_ARGUMENT = 'LIKE_ARGUMENT';

export const FLAG_ARGUMENT = "FLAG_ARGUMENT";
export const DELETE_ARGUMENT = 'DELETE_ARGUMENT';

export const LIKE_COMMENT = 'LIKE_COMMENT';
export const FLAG_COMMENT = 'FLAG_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const COMMENT = 'COMMENT';
export const ADD_ARGUMENT = 'ADD_ARGUMENT';

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";


