import {makeStyles} from "@material-ui/core/styles"

export default makeStyles((theme) => ({
    paper: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(4),
        borderRadius: "5px",
        marginBottom: "60px",
    },


    backgroundImageStyle: {
        display: "flex",
        margin: "0px auto",
        opacity: "0.8",
        maxWidth: "100%",
        borderRadius: "20px",
        backgroundColor: "transparent"
    },

    authBackgroundImage: {
        position: 'relative',
        display: "flex",
        margin: "0px auto",
        borderRadius: "20px",
        backgroundColor: "transparent",
        height: "100vh"
    },

    paperBorder: {
        border: "4px solid #FE876B",
        padding: "30px",
        borderRadius: "10px"
    },
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
        },
    }, 
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    signInHeader: {
        fontFamily: 'Inconsolata',
        fontSize: "25px",
        marginTop: "0px",
        color: "#3D3B3C",
        fontWeight: 900,
        // borderBottom: "2.5px solid #fff",
        padding: 1.6,
    },
    authDivider: {
        // border: "1px solid #FE5E38",
        width: "70%",
        margin: "0px 0px 10px"
    },
    authWelcome: {
        fontFamily: "Pacifico",
        color: "#3D3B3C",
        fontSize: "19px",
        // CENTER
        display: "flex",
        justifyContent: "center",
        margin: "0px auto 3px"
    },
    authWelcomeName: {
        fontFamily: "Pacifico",
        color: "#838383",
        fontSize: "19px",
        borderBottom: "2px solid #838383",
        paddingBottom: "0px",
        // marginBottom: "0px",
    
             // CENTER
             display: "flex",
             justifyContent: "center",
             margin: "0px auto 0px"

    },
    authWelcomeSub: {
        fontFamily: "Inconsolata",
        color: "#3D3B3C",
        fontSize: "13px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                     margin: "5px 10px 17px",
                     textAlign: "center"
    },
    authWelcomeRequired: {
        fontFamily: "Urbanist",
        color: "#FE5E38",
        fontSize: "16px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                     margin: "10px auto 7px",
                     textAlign: "center" 
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },

    fromControlStyle: {
      // margin: "10px 20px",
      width: '100%',
      marginTop: theme.spacing(3),
    },
    suburbNote: {
        fontFamily: "Urbanist",
        color: "#818181",
        fontSize: "13px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                    //  margin: "10px auto 7px",
                     textAlign: "center",
                     margin: "10px 20px 0px"
    },

    submit: {
        margin: theme.spacing(2, 0, 1),
        // backgroundColor: '#3D3B3C',
        backgroundImage: "radial-gradient(circle, #5b5956, #423F3E, #3D3B3C);",



        color: "#e3e0dc",
        fontFamily: 'Inconsolata, sans-serif',
        paddingTop: "9px",
        fontWeight: 900,
        textTransform: "none"
    },
    signUpSwitch: {
        // marginTop: "15px",
        fontFamily: 'Open Sans',
        fontWeight: 500,
        fontSize: "10px",
        color: "#5F5B6B",
        // backgroundColor: "#fff",
        textAlign: "center",
        cursor: "pointer",
        padding: "10px 0px 0px",
        display: "flex",
        flexDirection: "row"
    },

    forgotPassword: {
        marginTop: "8px",
        fontFamily: 'Open Sans',
        fontWeight: 500,
        fontSize: "13px",
        color: "#5F5B6B",
        textAlign: "center",
        cursor: "pointer",
    },

    // CHECKBOX:

    checkBoxText: {
        fontFamily: "Urbanist",
        fontSize: "13px",
        color: "#FE5E38",
        margin: " 0px 15px 20px"
    },
}))








// import { makeStyles } from '@material-ui/core/styles';

// export default makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: theme.spacing(2),
//   },
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//     },
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   googleButton: {
//     marginBottom: theme.spacing(2),
//   },
// }));