import React, {useEffect, useState} from 'react'
import { Container, Grow, Grid, Typography, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Explore from './../assets/exploreButton2.png';

import { getPolls } from '../../actions/posts';
import PollsPopular from '../Polls/PollsPopular';

import useStyles from './styles.js';

import authBackground from "./images/authBackground.png"
import iphoneAuthBackground from "./images/iphoneAuthBackground.png"

const ExplorePopular = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [user] = useState(JSON.parse(localStorage.getItem('profile')));

   useEffect(() => {
     dispatch(getPolls());
   }, [currentId, dispatch]);
      
  if (!user?.result?.name) {
    return (
      <div className={classes.authBackgroundImage}>
      <div className="authBackgroundImage1">
        <img className={classes.backgroundImageStyle} src={authBackground} alt=""/>
      </div>
      <div className="authBackgroundImage2">
        <img className={classes.backgroundImageStyle} src={iphoneAuthBackground} alt=""/>
      </div>
        <div style={{position: 'absolute', top: "5vh", left: 0, right: 0, bottom: 0}}>
    <div className={classes.paper}>

      <Typography className={classes.signInFlag}>
      Please <Link style={{textDecoration: "none"}}to="/auth"><span style={{textDecoration: "none", fontWeight: 700, color: "#838383"}}>sign in</span></Link> to interact on <span style={{fontFamily: "Orelega One", fontSize: "25px", fontWeight: 900}}>Syted</span>
    </Typography>
    </div>
    </div>
    </div>
    )
  }

  return (
      <Grow in>
   <Container maxWidth="xl">
  <center>
  {/* <div className="paperLink">
          <div className="paperBorderLink">
          <div className="link-to-submission-container">
      
          <div className="link-to-submission-container-1">
             <p className="link-to-submission-container-1-text"> What's on your mind?</p> 
           </div>
           <div className="link-to-submission-container-2">
          <Link style={{textDecoration: "none"}} to="/submission">
    
          <Button className={classes.submissionButton}>
          <p className="submissionButtonText">Post a statement</p>
          </Button>
          </Link>
          </div>
          </div>
        </div>
      </div> */}


      {/* {user?.result?.email && 
  (user?.result?.exclusive === true || user?.result?.exclusivePaid === true ? (
    <div className="exclusiveMenu">
      <Link to={"/top"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
        <h1 className={`navBarOptions${location.pathname === "/top" ? " active" : ""}`}>Top Public</h1>
      </Link>

      <Link to={"/top-you-smarty-pants"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
        <h1 className={`navBarOptions${location.pathname === "/top-you-smarty-pants" ? " active" : ""}`}> Top Exclusive</h1>
      </Link>
    </div>
  ) : (
    null 
  ))
} */}

      </center>

  <Grid container justifyContent="center" alignItems="stretch" spacing={3} className={classes.gridContainer}>
  <Grid item xs={12} sm={6} md={9}>
   <PollsPopular setCurrentId={setCurrentId} />

    </Grid>
    </Grid>
    </Container>
    </Grow>
  )
}

export default ExplorePopular