import React, {useEffect, useState} from 'react'
import { Container, Grow, Grid, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "./styles.css"

import { getPolls } from '../../actions/posts';
// import PollsDaily from '../Polls/PollsDaily';
// import PollsNew from '../Polls/PollsNEW24Hours';

import PollsNewWeek from '../Polls/PollsNEW7Days';

import useStyles from './styles.js';

import Explore from './../assets/exploreButton2.png';


const ExploreDaily = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [user] = useState(JSON.parse(localStorage.getItem('profile')));



   useEffect(() => {
     dispatch(getPolls());
   }, [currentId, dispatch]);

  return (
    <Grow in>
    <Container maxWidth="xl">

      <center>


        {/* <div className="paperLink">
          <div className="link-to-submission-container">

             <p className="link-to-submission-container-1-text"> What's on your mind?</p> 
           </div>
   
     
           {user?.result ? (
           <Link style={{textDecoration: "none"}} to="/submission">
          <Button className={classes.submissionButton}>
          <p className="submission-button-text">Post a statement</p>
          </Button>
          </Link>
           ) : (
          <Link style={{textDecoration: "none"}} to="/auth">
          <Button className={classes.submissionButton}>
          <p className="submission-button-text">Post a statement</p>
          </Button>
          </Link>
           )}
          </div>
     */}

      {/* <NewHereModal/> */}
      
      {/* {!user?.result && 
      <div>
      <Link to="/home" style={{textDecoration: "none"}}>
      <div className={classes.newHereButton}>New here?</div>
      </Link>
    </div>
      } */}


{/* PUBLIC EXCLUSIVE */}
{/* 
{user?.result?.email && 
  (user?.result?.exclusive === true || user?.result?.exclusivePaid === true ? (
    <div className="exclusiveMenu">
      <Link to={"/"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
        <h1 className={`navBarOptions${location.pathname === "/" || location.pathname === "/explore" ? " active" : ""}`}>Public</h1>
      </Link>

      <Link to={"/you-smarty-pants"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
        <h1 className={`navBarOptions${location.pathname === "/you-smarty-pants" ? " active" : ""}`}>Exclusive</h1>
      </Link>
    </div>
  ) : (
    null 
  ))
} */}


      </center>

      <div container justifyContent="center" alignItems="stretch" className={classes.gridContainer}>

      <Grid>
      {/* <PollsDaily setCurrentId={setCurrentId} /> */}
    

      <PollsNewWeek setCurrentId={setCurrentId} />

      </Grid>

      </div>
    </Container>
    </Grow>

  )
}


export default ExploreDaily

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   backgroundColor: "#FE876B",
//   textAlign: "center",
//   fontFamily: 'Urbanist',
//   fontSize: "20px",
//   border: '2px solid #fff',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
//   borderRadius: "20px"
// };



// ARGUMENT MODAL SUBMISSION:
// function NewHereModal({post}) {
//   const [open, setOpen] = React.useState(false);
//   const classes = useStyles();

//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <div> 

//         <Button className={classes.newHereButton} 
//         onClick={handleOpen}
//         >
//          New here?
//          </Button>

// </div> 
           
//       <Modal
//         open={open}
//         onClose={handleClose}
//       >
//         <Box sx={{ ...style, width: 400 }}>
//       <div>
//           <p className={classes.newHereTitle}>Welcome to <span style={{fontFamily: "Orelega One", fontSize: "21px"}}>Syted</span>!</p>
//           <p className={classes.newHereSubTitle}>The <span style={{fontFamily: "Orelega One"}}>Syted</span> community is here to help you <span style={{fontFamily: "Orelega One"}}>see</span> the world <span style={{fontFamily: "Orelega One"}}>differently</span>.</p>

//           <div className={classes.newHereBorder}></div>


//           <p className={classes.newHereSmallTitle}>How <span style={{fontFamily: "Orelega One", fontSize: "20px"}}>Syted</span> works:</p>
//           <p className={classes.newHereIntrcutions}>There are <span style={{fontWeight: 700}}>10 post slots</span> available every <span style={{fontWeight: 700}}>24 hours</span>.</p>

//           <p className={classes.newHereIntrcutions2}>If a post is deleted by the author or overly downvoted by the community, a new slot becomes available.</p>

//           <p className={classes.newHereIntrcutions}>Each post will be live for <span style={{fontWeight: 700}}>7 days</span>.</p>
//           {/* <p className={classes.newHereIntrcutions2}>If a post is well-received during this time and receives many upvotes, it may climb the ranks and appear among the top posts for the <span style={{fontWeight: 700}}>Week</span>. It could even reach the highest ranks within the <span style={{fontWeight: 700}}>Top</span>  tab.</p> */}
         
//           <p className={classes.newHereIntrcutions2}>If a post is well-received during this time and receives many upvotes, it may climb the ranks and appear among the highest ranks within the <span style={{fontWeight: 700}}>Top</span> tab.</p>
//           <p className={classes.newHereIntrcutions2}>For all live posts, the community can <span style={{fontWeight: 700}}>add positions</span> and <span style={{fontWeight: 700}}>star</span> the ones they endorse.</p>


//           <p className={classes.newHereIntrcutions}>After 7 days, posts will go to <span style={{fontWeight: 700}}>view mode</span>, and interaction is disabled. </p>
          
//           <p className={classes.newHereIntrcutions}>You can always check your posts' progress in the <span style={{fontWeight: 700}}>My Posts</span>  tab.</p>


//           <div className={classes.newHereBorder}></div>

//           <p className={classes.newHereSubTitle}>Go get some insights!</p>
//       </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }



        // if (!user?.result?.name) {
        //   return (
        //     <div className={classes.authBackgroundImage}>
        //     <div className="authBackgroundImage1">
        //       <img className={classes.backgroundImageStyle} src={authBackground} alt=""/>
        //     </div>
        //     <div className="authBackgroundImage2">
        //       <img className={classes.backgroundImageStyle} src={iphoneAuthBackground} alt=""/>
        //     </div>
        //       <div style={{position: 'absolute', top: "5vh", left: 0, right: 0, bottom: 0}}>
        //   <Paper className={classes.paper}>
 
        //     <Typography className={classes.signInFlag}>
        //       Please <Link style={{textDecoration: "none"}}to="/auth"><span style={{textDecoration: "none", fontWeight: 700, color: "#838383"}}>sign in</span></Link> to interact on <span style={{fontFamily: "Orelega One", fontSize: "25px", fontWeight: 900}}>Syted</span>
        //   </Typography>
        //   </Paper>
        //   </div>
        //   </div>
        //   )
        // }

