import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';

import Poll from './Poll/Poll';
import useStyles from './styles';
import ScrollToTopButton from './ScrollToTopButton';

const PollsNewWeek = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();

  const filteredPosts = posts
    .filter((post) => {
      const currentDate = new Date();
      const startOfCurrentDay = new Date(currentDate.setHours(0, 0, 0, 0)); // Start of the current day
      const endOfLast7Days = new Date(startOfCurrentDay.getTime() - (7 * 24 * 60 * 60 * 1000)); // End of the last 7 days
      const startOfLast14Days = new Date(endOfLast7Days.getTime() - (7 * 24 * 60 * 60 * 1000)); // Start of the last 14 days
      const postDate = new Date(post.createdAt);
      return postDate >= startOfLast14Days && postDate < endOfLast7Days;
    })
    .map((post) => ({ ...post, score: post.likesTotal - post.dislikesTotal }))
    .sort((a, b) => b.score - a.score)
    .slice(0, 1) // Get only the top post
    .map((post, index) => (
      <div className={classes.postGrid} key={post._id}>
        <Poll post={post} setCurrentId={setCurrentId} rank={index + 1} />
      </div>
    ));

  if (!posts.length && !isLoading)
    return (
      <div className={classes.errorText}>
        Oops! No results, please double check you have searched correctly
      </div>
    );

  return isLoading ? (
    <div style={{ height: '100vh' }}>
      <CircularProgress style={{ color: '#3D3B3C', margin: '15% 49%' }} size="1.5rem" />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <Grid style={{ justifyContent: 'center' }} className={classes.container} container alignItems="stretch" spacing={4}>
        {filteredPosts}
      </Grid>
      <ScrollToTopButton />


    </div>
  );
};

export default PollsNewWeek;
