import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
appBar: {
  marginTop: '0px',
  marginBottom: "0px",
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 0px',
},
heading: {
color: '#FE876B',
textDecoration: 'none',
fontFamily: 'Orelega One',
fontSize: "35px",
fontWeight: 900,
},
image: {
marginLeft: '15px',
},

menuButton: {
  color: '#FE6E4B',
  margin: "0px auto"
},

navBarOptions: {
fontSize: 17,
color: '#FE6E4B',
fontFamily: 'Urbanist',
letterSpacing: 0.5,
marginTop: 20,
marginLeft: 85,
borderBottom: "4px solid #FE876B",
padding: 2,
},

active: {
  color: "white",
  fontFamily: "Orelega One",
  fontSize: "10px"

}, 

toolbar: {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '400px',
},

profile: {
display: 'flex',
flexDirection: "row",
justifyContent: 'space-between',
marginBottom: "10px"
// maxWidth: '400px',
},

userName: {
display: 'flex',
alignItems: 'center',
fontSize: "0.7rem",
color: '#3D3B3C',
fontFamily: "Open Sans",
marginRight: "3px",
marginTop: "6px",
fontWeight: 500
},

brandContainer: {
display: 'flex',
alignItems: 'center',
},

purple: {
color: "#FFF",
backgroundColor: "#fd876b",
marginRight: 8,
padding: 0,
width: "30px",
height: "30px",
marginTop: "8px"
},

logout: {
  borderRadius: "4px",
  backgroundColor: '#3D3B3C',
  color: "#FFF",
  fontFamily: 'Urbanist',
  paddingTop: "0px",
  // textTransform: "capitalize",
  marginTop: "0px 0px 40px",
  // marginBottom: "50px",
  height: "28px",
  display: "flex",
  justifyContent: "center",
  fontWeight: 700,
  fontSize: "15px",
  textTransform: "none",
  fontFamily: "Inconsolata",
},

signInButton: {
borderRadius: "4px",
backgroundColor: '#3D3B3C',
color: "#FFF",
fontFamily: 'Urbanist',
paddingTop: "0px",
// textTransform: "capitalize",
marginTop: "0px 0px 40px",
height: "28px",
display: "flex",
justifyContent: "center",
fontWeight: 700,
fontSize: "15px",
textTransform: "none",
fontFamily: "Inconsolata",

},


menuItems: {
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
  },}

}));


// import { makeStyles } from '@material-ui/core/styles';
// import { deepPurple } from '@material-ui/core/colors';

// export default makeStyles((theme) => ({
//   appBar: {
//     borderRadius: 15,
//     margin: '30px 0',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '10px 50px',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column',
//     },
//   },
//   heading: {
//     color: theme.palette.primary.main,
//     textDecoration: 'none',
//   },
//   image: {
//     marginLeft: '10px',
//     marginTop: '5px',
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     width: '400px',
//     [theme.breakpoints.down('sm')]: {
//       width: 'auto',
//     },
//   },
//   profile: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '400px',
//     alignItems: 'center',
//     [theme.breakpoints.down('sm')]: {
//       width: 'auto',
//       marginTop: 20,
//       justifyContent: 'center',
//     },
//   },
//   logout: {
//     marginLeft: '20px',
//   },
//   userName: {
//     display: 'flex',
//     alignItems: 'center',
//     textAlign: 'center',
//   },
//   brandContainer: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   jebLogo: {
//     fontSize: "35px",
//     textDecoration: "none",
//     fontFamily: 'Orelega One',
//     color: "#fe5e36",
//     fontWeight: 700
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500],
//   },
// }));