import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:5000' });
// const API = axios.create({ baseURL: 'https://syteduser.herokuapp.com/' });
const API = axios.create({ baseURL: 'https://sytedexclusive-df4210f421ce.herokuapp.com/' });

// Ensures that user is logged in so user can properly interact BELOW IS ORIGINAL VERSION
// API.interceptors.request.use((req) => {
//   if (localStorage.getItem('profile')) {
//     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
//   }
//   return req;
// });

API.interceptors.request.use((req) => {
  const unprotectedUrls = ['/home', '/contact', '/explore', '/explore/:id']; // add URLs that don't require authentication
  if (localStorage.getItem('profile') && !unprotectedUrls.some((url) => req.url.startsWith(url))) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});



export const fetchPolls = (page) => API.get(`/posts?page=${page}`);
// export const fetchPolls = () => API.get('/posts');
export const fetchPost = (id) => API.get(`/posts/${id}`);

export const fetchPostsBySearch = ({ search }) => API.get(`/posts/search?searchQuery=${search || 'none'}`);

export const createPost = (newPost) => API.post('/posts', newPost);

export const createPoll = (newPoll) => API.post('/posts', newPoll);

export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const dislikePost = (id) => API.patch(`/posts/${id}/dislikePost`);

export const flagPost = (id) => API.patch(`/posts/${id}/flagPost`);



export const likeArgumentPost = (postId, argumentId) => API.patch(`/posts/${postId}/likeArgumentPost/${argumentId}`);
export const flagArgumentPost = (postId, argumentId) => API.patch(`/posts/${postId}/flagArgumentPost/${argumentId}`);
export const deleteArgument = (postId, argumentId) => API.delete(`/posts/${postId}/deleteArgument/${argumentId}`);


export const likeCommentPost = (postId, commentId) => API.patch(`/posts/${postId}/likeCommentPost/${commentId}`);
export const deleteComment = (postId, commentId) => API.delete(`/posts/${postId}/deleteComment/${commentId}`);
export const flagCommentPost = (postId, commentId) => axios.patch(`/posts/${postId}/flagCommentPost/${commentId}`);



export const comment = (id, newComment) => API.post(`/posts/${id}/commentPost`, {newComment});

export const newArgument = (id, newArgumentSubmit) => API.post(`/posts/${id}/setArgument`, {newArgumentSubmit});

export const goToPrompt = (value, id) => API.post(`/posts/${id}/goToPromptPost`, {value});
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);


// "Hey database, get me some data and return it to me..."
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);


// export const updateProfile = (id, updateProfile) => API.patch(`/user/${id}`, updateProfile );






// COMMENTARY:

export const fetchComs = (page) => API.get(`/coms?page=${page}`);
export const fetchCom = (id) => API.get(`/coms/${id}`);
export const fetchComsBySearch = (searchQuery) => API.get(`/coms/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createCom = (newCom) => API.post('/coms', newCom);
export const likeCom = (id) => API.patch(`/coms/${id}/likeCom`);
export const commentCom = (value, id) => API.post(`/coms/${id}/commentCom`, {value});

export const updateCom = (id, updatedCom) => API.patch(`/coms/${id}`, updatedCom);
export const deleteCom = (id) => API.delete(`/coms/${id}`);

