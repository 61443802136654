import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography, Container } from '@material-ui/core';
import {  useHistory } from 'react-router-dom';

import Snackbar from '@material-ui/core/Snackbar';

import { signin, signup } from '../../actions/auth';

import Input from './Input';

import useStyles from './styles';
import "./styles.css"



import InputName from './InputName';

import authBackground from "./images/authBackground.png"
import iphoneAuthBackground from "./images/iphoneAuthBackground.png"



const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', age: '', gender: '', occupation: '', ip: '', pseudoname: '' };

const Auth = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const [ip, setIp] = useState('');
  const [open, setOpen] = useState(false);
  const [openSignIn, setSignInOpen] = useState(false);

  const handleClose = () => {
    // setOpen(false);
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip));
  }, []);


    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const switchMode = () => {
      setFormData(initialState);
      setIsSignup((prevIsSignup) => !prevIsSignup);
      setShowPassword(false);
    };

    

    const handleSubmit = (e) => {
      e.preventDefault()
      if (isSignup) {
          dispatch(signup({ ...formData, createdAt: new Date()}, history)); 
      } else {
        dispatch(signin(formData, history));

      } 

      setTimeout(() => {
        setOpen(true);
      }, 3800); // delay of 2 seconds (2000 milliseconds)

      setTimeout(() => {
        setSignInOpen(true);
      }, 3800); // delay of 2 seconds (2000 milliseconds)

    };

    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, ip: `${ip}` });

    function handleClick() {
      setIsVisible(!isVisible);
    }
  

  return (
  <div style={{height: "100%"}}>
    <div>
      {/* <div className="authBackgroundImage1">
        <img className={classes.backgroundImageStyle} src={authBackground} alt=""/>
      </div>
      <div className="authBackgroundImage2">
        <img className={classes.backgroundImageStyle} src={iphoneAuthBackground} alt=""/>
      </div> */}
        <div style={{position: 'absolute', top: "10vh", left: 0, right: 0, bottom: 0}}>
    <Container component="main" maxWidth="xs">

{/* USED TO BE PAPER */}
    <div className={classes.paper} elevation={6}>

    <Typography className={classes.signInHeader} component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>

    <div fullWidth className={classes.signUpSwitch}  onClick={switchMode}>
                { isSignup ? <><span style={{fontSize: "15px"}}>Have an account?</span><span style={{fontSize: "15px"}}> &nbsp; Sign in </span></> : <><span style={{fontSize: "15px"}}>No account?</span><span style={{fontSize: "15px"}}> &nbsp;Sign up </span></>}
              </div>


      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          { isSignup && (
          <>
          {/* <p className={classes.authWelcome}>Hell00o <span className={classes.authWelcomeName}> &nbsp;no name&nbsp; </span>! </p>
    
          <p className={classes.authWelcomeSub}>If you'd like to remain anonymous, feel free to exclude your name (we will refer to you as your username).</p>
       */}
            <InputName name="firstName" label="Name" handleChange={handleChange} autoFocus half />
            <InputName name="lastName" label="Surname" handleChange={handleChange} half />

            <div className={classes.authDivider}></div>
            <InputName name="pseudoname" label="Username*" handleChange={handleChange} autoFocus full required={true} />



{ isSignup && 

<Snackbar
        open={open}
        style={{textAlign: "center", color: "#FE5E38", fontFamily: "Urbanist"}}
        onClose={(event, reason) => {
          setSignInOpen(false);
        }}
        message="Oops, it looks like that username or email is already registered. If you've made an account before, please try log in to your account. Otherwise, please use a different username and or email address."
      />
  //   <Snackbar
  //   open={open}
  //   onClose={handleClose}
  //   message="Oops, it looks like that username or email is already registered. If you've made an account before, please try log in to your account. Otherwise, please use a different username and or email address."
  //   action={
  //     <>
  //       <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
  //         <CloseIcon fontSize="small" />
  //       </IconButton>
  //     </>
  //   }
  // />

    }

            <div className={classes.authDivider}></div>


          </>
          )}




          <Input name="email" label="Email" handleChange={handleChange} type="email" />

          <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
          { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
       
        </Grid>

        
      <Button type="submit" fullWidth variant="contained" className={classes.submit}>
          { isSignup ? 'Sign up' : 'Sign in' }
        </Button>

  
        <Grid container justifyContent="center">


          <div>
            {!isSignup && 
            <div>
      <div className={classes.forgotPassword} onClick={handleClick}>Forgotten password?</div>
      {isVisible && <div className={classes.forgotPassword}>Please email: <span style={{textDecoration: "underline", color: "#fff", fontSize: "14px"}}>help@syted.net</span></div>}
            </div>
            }
      </div>
            
       </Grid> 
      </form>
{/* USED TO BE PAPER */}
    </div>

    {!isSignup && 

<Snackbar
open={open}
style={{textAlign: "center", color: "#FE5E38", fontFamily: "Urbanist"}}
onClose={(event, reason) => {
  setSignInOpen(false);
}}
message="Oops, it looks like that email or password is just not quite right, please give it another go."
/>
    //   <Snackbar
    //   open={open}
    //   onClose={handleClose}
    //   message="Oops, it looks like that email or password is just not quite right, please give it another go."
    //   action={
    //     <>
    //       <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
    //         <CloseIcon fontSize="small" />
    //       </IconButton>
    //     </>
    //   }
    // />
}


  </Container>
</div>

  </div>
  </div>
  )
}

export default Auth
