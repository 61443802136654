import React from 'react'
import { Container } from '@material-ui/core';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Auth from './components/Auth/Auth';
import LandingPage from './components/LandingPage/LandingPage';

import Submit from './components/PollSubmission/Submit';
import TopicSelection from './components/TopicSelection/Daily';

import ExploreDaily from './components/Explore/Daily';
import ExplorePopular from './components/Explore/Popular';

import Contact from './components/Misc/Contact';

// import ExploreWeek from './components/Explore/Week';

// import ExploreMyPolls from './components/Explore/MyPolls';

// import Profile from './components/Profile/Profile';

// import TermsConditions from './components/Misc/TermsConditions';
// import PrivacyPolicy from './components/Misc/PrivacyPolicy';

// import EEDaily from './components/ExploreExclusive/EEDaily';
// import SubmitExclusive from './components/PollSubmission/SubmitExclusive';
// import EEPopular from './components/ExploreExclusive/EEPopular';

const App = () => {

  return (
    <BrowserRouter>
    <Container maxWidth="xl">
      <Navbar/>
      <Switch>

      {/* {user?.result?.exclusive ? (
            <Route path="/" exact component={EEDaily} />
          ) : (
            <Route path="/" exact component={ExploreDaily} />
          )} */}

      <Route path="/topics" exact component={TopicSelection} />
       
      <Route path="/" exact component={TopicSelection} />
      <Route path="/auth" exact component={Auth} />
  
        <Route path="/about" exact component={LandingPage} />
        <Route path="/submission" component={Submit} />

        <Route path="/explore" exact component={ExploreDaily} />

                {/* CONTACT  */}
                <Route path="/contact" exact component={Contact}/>
 
   <Route path="/top" exact component={ExplorePopular} />


{/* TOP - EXCLUSIVE: Decomissioned September 2024*/}


        {/* {user?.result?.exclusive ? (
            <Route path="/explore" exact component={EEDaily} />
          ) : (
            <Route path="/explore" exact component={ExploreDaily} />
          )} */}


        {/* <Route path="/you-smarty-pants" exact component={EEDaily} />      */}
       {/* <Route path="/submission-smarty-pants" component={SubmitExclusive} /> */}
        {/* <Route path="/top-you-smarty-pants" exact component={EEPopular} />  */}


{/* Explore Weekly - decommisioned Auguest 2024 */}
        {/* <Route path="/week" exact component={ExploreWeek} /> */}
 

{/* MY POLLS - decomissioned Auguest 2024 */}
        {/* <Route path="/my-posts" exact component={ExploreMyPolls} /> */}

          {/* <Route path="/explore/search" exact component={Explore} /> */}
        {/* <Route path="/profile" component={Profile}/> */}

        {/* <Route path="/explore/:id" post={post} component={PollDetails}/> */}


        {/* <Route path="/terms-and-conditions" exact component={TermsConditions}/>
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/> */}

        {/* <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/explore" />)} /> */}

      </Switch>
    </Container>
    </BrowserRouter>
  )
}


export default App