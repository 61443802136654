import React, {useState} from 'react';
import { Button, Modal, Box } from '@material-ui/core/';

import useStyles from './styles';
import "./styles.css"


// IMAGES:
import Downvote from "../assets/downVote.png"
import Upvote from "../assets/upVote.png"


import FillDownvote from "../assets/fillDownVote.png"
import FillUpvote from "../assets/fillUpVote.png"


import { useDispatch } from 'react-redux';

import { likePost, dislikePost, deletePost, flagPost } from '../../../actions/posts';
import PollViewModeArgument from './PollViewModeArgument';
import PollViewModeCommentSection from './PollViewModeCommentSection';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import Dots from './assets/dots.png';
import DotsLight from './assets/dotsLight.png';



const PollViewMode = ({post, currentId, setCurrentId, rank}) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));

  const dispatch = useDispatch();

  const [showDiscussion, setShowDiscussion] = useState(false)

    // FOR "SHARE" FEATURE
    const [open, setOpen] = useState(false);


    const [showDiv, setShowDiv] = useState(false);
  


    const handleMouseEnter = () => {
      setShowDiv(true);
    };






  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };



    // const Likes = () => {
    //   if (post?.likes?.length > 0) {
    //     return post.likes.find((like) => like === (user?.result?._id))
    //       ? (
    //         <>

    //            <img className={classes.pollVoteAmplify} src={FillUpvote} alt="" />
    //           {/* <p className={classes.userActionedStyle}>{post.likesTotal}</p> */}
    //         </>
    //       ) : (
    //         <>
    //       {/* <p className="pollVTextStyle">Upvote</p> */}
    //           <img className={classes.pollVoteAmplify} src={Upvote} alt="" />
    //           {/* <p className={classes.userNotActionedStyle}>{post.likesTotal}</p> */}
    //         </>
    //       );
    //   }
    
    //   return (
    //     <>
    //          {/* <p className="pollVTextStyle">Upvote</p> */}
    //       <img className={classes.pollVoteAmplify} src={Upvote} alt="" />
    //       {/* <p className={classes.userNotActionedStyle}>{post.likesTotal}</p> */}
    //     </>
    //   );
    // };

    // const Dislikes = () => {
    //   if (post?.dislikes?.length > 0) {
    //     return post.dislikes.find((like) => like === (user?.result?._id))
    //       ? (
    //         <>
    //           {/* <p className={classes.userActionedStyle}>{post.dislikesTotal}</p> */}
    //           <img className={classes.pollVoteAmplify} src={FillDownvote} alt="" />
    //         </>
    //       ) : (
    //         <>
    //              {/* <p className={classes.userNotActionedStyle}>{post.dislikesTotal}</p> */}
    //           <img className={classes.pollVoteAmplify} src={Downvote} alt="" />
    //           {/* <p className="pollVTextStyle">Downvote</p> */}
    //         </>
    //       );
    //   }
    
    //   return (
    //     <>
    //       {/* <p className={classes.userNotActionedStyle}>{post.dislikesTotal}</p> */}
    //       <img className={classes.pollVoteAmplify} src={Downvote} alt="" />
    //       {/* <p className="pollVTextStyle">Downvote</p> */}
    //     </>
    //   );
    // };


    const FlagPost = () => {
      if (post?.flags?.length > 0) {
        return post.flags.find((like) => like === (user?.result?._id))
          ? (
            <>
              <p className={classes.selectArgumentDashboardButtonWhiteFlag}>Unflag</p>
            </>
          ) : (
            <>
    <p className={classes.selectArgumentDashboardButtonWhiteFlag}>Flag Topic</p>
            </>
          );
      }
    
      return (
        <>
    <p className={classes.selectArgumentDashboardButtonWhiteFlag}>Flag Topic</p>
        </>
      );
    };

    
    // const now = new Date();
    function getTimeAgo(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return 'just now';
      }
      const now = new Date();
      const diffMs = now - date;
    
      if (diffMs < 60000) {
        return 'just now';
      } else if (diffMs < 3600000) {
        const diffMins = Math.floor(diffMs / 60000);
        return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
      } else if (diffMs < 86400000) {
        const diffHrs = Math.floor(diffMs / 3600000);
        return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
      } else {
        const diffDays = Math.floor(diffMs / 86400000);
        return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
      }
    }

    const toggleDiscussion = () => {
      setShowDiscussion(!showDiscussion);
    };

    return (

      <div className="containerFull">



<p className="containerFull-rank">{rank}. </p>
<ReadMoreComponent post={post}/>
        
        
      {/* <p className="pollHeaderContext"> {post.context}</p> */}
{/* 
      <div className={classes.pollVoteContainer}>

      <Button style={{borderRadius: "10%", margin: "0px", padding: "0px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(likePost(post._id))}>
          <Likes />
      </Button>

      <Button style={{borderRadius: "10%", margin: "0px", padding: "0px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(dislikePost(post._id))}>
          <Dislikes />
      </Button>
      </div> */}

       {/* <div className="container">

    </div> */}
      
      <div className="containerSides">

{/* <div className={classes.pollVoteContainer}>
      <Button style={{borderRadius: "10%", margin: "0px", padding: "0px 10px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(dislikePost(post._id))}>
          <Dislikes />
      </Button>

      <Button style={{borderRadius: "10%", margin: "0px", padding: "0px 10px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(likePost(post._id))}>
          <Likes />
      </Button>
      </div> */}




      {post.flags?.length < 30 ? (   
      
      <> 
        <div className="leftSide">



{/* QUOTES - default hidden */}
      {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        {post.context && (
      <div
        className="hoverDiv"
        onClick={handleMouseEnter}
      >
  <img style={{ cursor: "pointer", width: '25px', display: showDiv ? 'none' : 'block' }} src={Quotes} alt="''" />
      </div>
      )}
          
      {showDiv && post.context && (
        <div className="pollHeaderContext">
          {post.context}
        </div>
      )}
    </div> */}





  

{post.arguments?.length > 0 ? (

<div className={classes.topArgumentContainer}>

  <p className={classes.topArgumentHeader}> Top Positions </p>
{post.arguments
  .filter((argument) => argument.position === "agree")
  .filter((argument) => argument.flagsTotal < 30)
  .sort((a, b) => b.likesTotal - a.likesTotal)
  .slice(0, 1)
  .map((argument, index) => (
    <div className="argumentStyle" key={index}>
    <div className={classes.argumentStylePositionAndDate}>
      <p className={classes.topArgumentStylePosition}>{argument.position}</p>
      <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
    </div>
      {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
      <div className={classes.topArgumentSpread}>
      {argument.statement.split('\n\n').map((paragraph, index) => (
  <div className={classes.topArgumentSpacing} key={index}>
    <p className="argumentStyleStatement">{paragraph}</p>
  </div>
))}
    </div>
    </div>
  ))}




{post.arguments
  .filter((argument) => argument.position === "undecided")
  .filter((argument) => argument.flagsTotal < 30)
  .sort((a, b) => b.likesTotal - a.likesTotal)
  .slice(0, 1)
  .map((argument, index) => (
    <div className="argumentStyle" key={index}>
      <div className={classes.argumentStylePositionAndDate}>
      <p className={classes.topArgumentStylePosition}>{argument.position}</p>
      <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
    </div>
      {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
      <div className={classes.topArgumentSpread}>
      {argument.statement.split('\n\n').map((paragraph, index) => (
  <div className={classes.topArgumentSpacing} key={index}>
    <p className="argumentStyleStatement">{paragraph}</p>
  </div>
))}
  </div>
    </div>
  ))}




{post.arguments
  .filter((argument) => argument.position === "disagree")
  .filter((argument) => argument.flagsTotal < 30)
  .sort((a, b) => b.likesTotal - a.likesTotal)
  .slice(0, 1)
  .map((argument, index) => (
    <div className="argumentStyle" key={index}>
      <div className={classes.argumentStylePositionAndDate}>
      <p className={classes.topArgumentStylePosition}>{argument.position}</p>
      <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
    </div>
      {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
      <div className={classes.topArgumentSpread}>
      {argument.statement.split('\n\n').map((paragraph, index) => (
  <div className={classes.topArgumentSpacing} key={index}>
    <p className="argumentStyleStatement">{paragraph}</p>
  </div>
))}


  </div>
    </div>
  ))}







</div>
    ) : (
      <div className={classes.topArgumentContainer}>
      <p className={classes.topArgumentHeader}> Top Supported Positions </p>
      {/* <p className={classes.noArgumentsNotification}>No top positions</p> */}
      <img style={{margin: "5px auto", display: "flex", justifyContent: "center", width: "30px"}} src={DotsLight} alt=""/>
      </div>
    )}

</div> 


<div className="rightSide">
<PollViewModeArgument post={post} setCurrentId={setCurrentId} />



  </div>




</> ) : (<></>)}
    </div>

    <div className="bottomSide">
    <div className={classes.postOptionDashboard}>
  
  <div className={classes.postOptionDashboardAlign}>
    <p className={classes.postStyleDateTextStyle}>Posted:&nbsp;<span> {getTimeAgo(post.createdAt)}</span></p>
  
  <div style={{display: "flex", flexDirection: "row", margin: "0px auto"}}>
    <Button className={classes.selectArgumentDashboardButtonWhiteDiscussion} onClick={toggleDiscussion}>
    {showDiscussion ? 'Hide' : <>Discussion</>}
    </Button>

    {user?.result?._id !== post?.creator && (
    <Button className={classes.selectArgumentDashboardButtonWhiteDiscussion} disabled={!user?.result} onClick={() => dispatch(flagPost(post._id))}>
    <FlagPost/>
  </Button>
)}




        {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
      <>
      <NestedModal post={post} setCurrentId={setCurrentId} />
      </>
    )}
  
  </div>

  </div>

      <div className={classes.postOptionDashboardRow}>

        {/* <Button className={classes.selectArgumentDashboardButtonWhite} onClick={copyToClipboard}>
        Share Post
        </Button> */}

    {/* {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
      <>
      <NestedModal post={post} setCurrentId={setCurrentId} />
      </>
    )} */}

        {/* <Button className={classes.selectArgumentDashboardButtonWhite} disabled={!user?.result} onClick={() => dispatch(flagPost(post._id))}>
          <FlagPost/>
        </Button> */}
      </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Discussion URL saved to your clipboard!
                </Alert>
              </Snackbar>

</div>

{showDiscussion && (
<PollViewModeCommentSection post={post} />
)}
</div>
  
    </div>



    )
 

}

export default PollViewMode





const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: "#3D3B3C",
  color: "#e3e0dc",
  textAlign: "center",
  fontFamily: 'Urbanist',
  fontSize: "20px",
  border: '1px solid #e3e0dc',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "7px",
};


// ARGUMENT MODAL SUBMISSION:
function NestedModal({post}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div> 
      {/* <Button className={classes.selectArgumentDashboardButtonWhite}  */}
        <Button className={classes.selectArgumentDashboardButtonWhiteDiscussion} 
        onClick={handleOpen}
        >
         Delete
         </Button>
        </div> 
           
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style, width: 400 }}>
        <div className={classes.addArgumentInnerContainer}>
        <div style={{ width: '100%' }}>
          <p className={classes.addArgumentPreHeaderPrompt}>Are you sure you want to delete your post?</p>
          {/* <p className={classes.addArgumentHeader}>"{post.statement}"</p> */}
          {/* <ReadMoreComponentDelete post={post}/> */}
          <div className={classes.deleteArgumentAlign}>
          <Button style={{ fontSize: "1rem", fontWeight: 700, width: "45%", backgroundColor: "#e3e0dc", color: "#3D3B3C", margin: "8px", borderRadius: "4px", fontFamily: "Inconsolata"  }} fullWidth variant="contained" onClick={handleClose}>
            No
          </Button>
          <Button style={{ fontSize: "1rem", fontWeight: 700, width: "45%", backgroundColor: "#e3e0dc", color: "#3D3B3C", margin: "8px", borderRadius: "4px", fontFamily: "Inconsolata"  }} fullWidth variant="contained" 
            onClick={() => {
            dispatch(deletePost(post._id))
            handleClose()
          }}>
            Yes
          </Button>
          </div>
        </div>
      </div>
        </Box>
      </Modal>
    </div>
  );
}










function ReadMoreComponent({ post }) {
  const [showFullStatement, setShowFullStatement] = useState(false);
  const classes = useStyles();

  const handleReadMoreClick = () => {
    setShowFullStatement(!showFullStatement);
  };

  return (
    <div>
      <p onClick={handleReadMoreClick} className="pollHeaderStatement">
        {showFullStatement ? post.statement : post.statement.substr(0, 380)}
        {post.statement.length > 400 && (
          <span className="readMoreButtonArgument">

            {showFullStatement ? "...read less" : "...read more"}
          </span>
        )}
      </p>
    </div>
  );
}





function ReadMoreComponentDelete({ post }) {
  const [showFullStatement, setShowFullStatement] = useState(false);
  const classes = useStyles();

  const handleReadMoreClick = () => {
    setShowFullStatement(!showFullStatement);
  };

  return (
    <div>
      <p onClick={handleReadMoreClick} className={classes.pollHeaderStatementDelete}>
            {/* {showFullStatement ? post.statement : post.statement.substr(0, 200)} */}
            {showFullStatement ? `"${post.statement}"` : `"${post.statement.substr(0, 200)}"`}
        {post.statement.length > 400 && (
          <span className={classes.readMoreButtonArgumentDelete}>
            {showFullStatement ? "...read less" : "...read more"}
          </span>
        )}
      </p>
    </div>
  );
}











// import React, {useState} from 'react';
// import { Button, Modal, Box } from '@material-ui/core/';

// import useStyles from './styles';
// import "./styles.css"


// // IMAGES:
// import Downvote from "../assets/downVote.png"
// import Upvote from "../assets/upVote.png"

// import { useDispatch } from 'react-redux';

// import { deletePost} from '../../../actions/posts';
// import PollViewModeArgument from './PollViewModeArgument';
// import PollViewModeCommentSection from './PollViewModeCommentSection';

// import Snackbar from '@material-ui/core/Snackbar';


// const PollViewMode = ({post, currentId, setCurrentId, rank}) => {
//   const classes = useStyles();
//   const user = JSON.parse(localStorage.getItem('profile'));

//   const [showDiscussion, setShowDiscussion] = useState(false)
//   const [open, setOpen] = useState(false);

//   const handleOpen = () => {
//     setOpen(true);
//   };


//     function getTimeAgo(dateString) {
//       const date = new Date(dateString);
//       if (isNaN(date)) {
//         return 'just now';
//       }
//       const now = new Date();
//       const diffMs = now - date;
    
//       if (diffMs < 60000) {
//         return 'just now';
//       } else if (diffMs < 3600000) {
//         const diffMins = Math.floor(diffMs / 60000);
//         return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
//       } else if (diffMs < 86400000) {
//         const diffHrs = Math.floor(diffMs / 3600000);
//         return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
//       } else {
//         const diffDays = Math.floor(diffMs / 86400000);
//         return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
//       }
//     }

//     const toggleDiscussion = () => {
//       setShowDiscussion(!showDiscussion);
//     };


//     return (
 
//       <div className="pollContainer">
//              {post.flags?.length < 30 ? (   <> 

//       <p className="pollHeaderStatement">Rank #{rank}</p>

//       <div className={classes.pollVoteContainer}>

// <div className="pollViewModeButtonContainer">
//   <p className={classes.userNotActionedStyle}>{post.dislikesTotal}</p>
//   <img onClick={handleOpen} className={classes.pollVoteAmplifyDetails} src={Downvote} alt="Silence" />
// </div>

//       <p className="pollHeaderStatement">{post.statement}</p>
//       {/* <p className="pollHeaderContext"> {post.context}</p> */}
  
//       <div className="pollViewModeButtonContainer">
//           <img onClick={handleOpen} className={classes.pollVoteAmplifyDetails} src={Upvote} alt="Amplify" />
//           <p className={classes.userNotActionedStyle}>{post.likesTotal}</p>
//         </div>

//       </div>
   



   


// {post.arguments?.length > 0 ? (

// <div className={classes.topArgumentContainer}>
//   <p className={classes.topArgumentHeader}> Top Supported Positions </p>
// {post.arguments
//   .filter((argument) => argument.position === "agree")
//   .filter((argument) => argument.flagsTotal < 30)
//   .sort((a, b) => b.likesTotal - a.likesTotal)
//   .slice(0, 1)
//   .map((argument, index) => (
//     <div className="argumentStyle" key={index}>
//     <div className={classes.argumentStylePositionAndDate}>
//       <p className={classes.topArgumentStylePosition}>{argument.position}</p>
//       <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
//     </div>
//       {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
//       <div className={classes.topArgumentSpread}>
//       {argument.statement.split('\n\n').map((paragraph, index) => (
//   <div className={classes.topArgumentSpacing} key={index}>
//     <p className="argumentStyleStatement">{paragraph}</p>
//   </div>
// ))}
//   </div>
//     </div>
//   ))}


// {post.arguments
//   .filter((argument) => argument.position === "undecided")
//   .filter((argument) => argument.flagsTotal < 30)
//   .sort((a, b) => b.likesTotal - a.likesTotal)
//   .slice(0, 1)
//   .map((argument, index) => (
//     <div className="argumentStyle" key={index}>
//       <div className={classes.argumentStylePositionAndDate}>
//       <p className={classes.topArgumentStylePosition}>{argument.position}</p>
//       <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
//     </div>
//       {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
//       <div className={classes.topArgumentSpread}>
//       {argument.statement.split('\n\n').map((paragraph, index) => (
//   <div className={classes.topArgumentSpacing} key={index}>
//     <p className="argumentStyleStatement">{paragraph}</p>
//   </div>
// ))}
//   </div>
  
//     </div>
//   ))}


// {post.arguments
//   .filter((argument) => argument.position === "disagree")
//   .filter((argument) => argument.flagsTotal < 30)
//   .sort((a, b) => b.likesTotal - a.likesTotal)
//   .slice(0, 1)
//   .map((argument, index) => (
//     <div className="argumentStyle" key={index}>
//       <div className={classes.argumentStylePositionAndDate}>
//       <p className={classes.topArgumentStylePosition}>{argument.position}</p>
//       <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>
//     </div>
//       {/* <p className="argumentStyleStatement"> {argument.statement}</p> */}
//       <div className={classes.topArgumentSpread}>
//       {argument.statement.split('\n\n').map((paragraph, index) => (
//   <div className={classes.topArgumentSpacing} key={index}>
//     <p className="argumentStyleStatement">{paragraph}</p>
//   </div>
// ))}
//   </div>
//     </div>
//   ))}


// </div>

//     ) : (
//       <div className={classes.topArgumentContainer}>
//       <p className={classes.topArgumentHeader}> Top Supported Positions </p>
//       <p className={classes.noArgumentsNotification}>No top positions</p>
//       </div>
//     )}


// <PollViewModeArgument post={post} setCurrentId={setCurrentId} />


// <div className={classes.postOptionDashboard}>
  
//     <p className={classes.postStyleDateTextStyle}>Posted:&nbsp;<span style={{fontWeight: 600}}>{getTimeAgo(post.createdAt)}</span></p>

//     <div className={classes.postOptionDashboardRow}>
//     <Button className={classes.selectArgumentDashboardButtonWhite} onClick={toggleDiscussion}>
//   {showDiscussion ? 'Hide' : 'Show discussion'}
// </Button>


// {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
// <>
// <NestedModal post={post} setCurrentId={setCurrentId} />
// </>
//         )}

//         </div>
// </div>

// {showDiscussion && (
// <PollViewModeCommentSection post={post} />
// )}

// <Snackbar
//         open={open} autoHideDuration={3500} 
//         onClose={(event, reason) => {
//           setOpen(false);
//         }} 
//         message="Uh oh, you're in view mode. You can only intereact with posts in the Current feed."
//         style={{textAlign: "center"}}
//         />

// </> ) : (<></>)}
//     </div>

//     )
 

// }

// export default PollViewMode


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   backgroundColor: "#fd876b",
//   color: "#FFF",
//   textAlign: "center",
//   fontFamily: 'Urbanist',
//   fontSize: "20px",
//   border: '2px solid #fff',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
//   borderRadius: "20px",
// };


// // ARGUMENT MODAL SUBMISSION:
// function NestedModal({post}) {
//   const [open, setOpen] = React.useState(false);
//   const classes = useStyles();
//   const dispatch = useDispatch();

//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <div> 

//         <Button className={classes.selectArgumentDashboardButtonWhite} 
//         onClick={handleOpen}
//         >
//          Delete
//          </Button>

// </div> 
           
//       <Modal
//         open={open}
//         onClose={handleClose}
//       >
//         <Box sx={{ ...style, width: 400 }}>
//         <div className={classes.addArgumentInnerContainer}>
//         <div style={{ width: '100%' }}>
//           <p className={classes.addArgumentPreHeaderPrompt}>Are you sure you want to delete your post?</p>
//           {/* <p className={classes.addArgumentHeader}>"{post.statement}"</p> */}
//           <ReadMoreComponentDelete post={post}/>
//           <div className={classes.deleteArgumentAlign}>

//           <Button style={{ fontSize: "12px", fontWeight: 700, width: "45%", backgroundColor: "#fff", color: "#fd876b", margin: "8px", borderRadius: "5px", fontFamily: "Urbanist"  }} fullWidth variant="contained" onClick={handleClose}>
//             No
//           </Button>
        
//           <Button style={{ fontSize: "12px", fontWeight: 700, width: "45%", backgroundColor: "#fff", color: "#fd876b", margin: "8px", borderRadius: "5px", fontFamily: "Urbanist"  }} fullWidth variant="contained" 
//           onClick={() => {
//             dispatch(deletePost(post._id))
//             handleClose()
//           }}>

//             Yes
//           </Button>
//           </div>
//         </div>
//       </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// function ReadMoreComponentDelete({ post }) {
//   const [showFullStatement, setShowFullStatement] = useState(false);
//   const classes = useStyles();

//   const handleReadMoreClick = () => {
//     setShowFullStatement(!showFullStatement);
//   };

//   return (
//     <div>
//       <p onClick={handleReadMoreClick} className={classes.pollHeaderStatementDelete}>
//         {/* {showFullStatement ? post.statement : post.statement.substr(0, 200)} */}
//         {showFullStatement ? `"${post.statement}"` : `"${post.statement.substr(0, 200)}"`}
//         {post.statement.length > 400 && (
//           <span className={classes.readMoreButtonArgumentDelete}>
//             {showFullStatement ? "...read less" : "...read more"}
//           </span>
//         )}
//       </p>
//     </div>
//   );
// }