
import React from 'react'

import useStyles from './styles';
import "./styles.css"

const Contact = () => {
  const classes = useStyles();

  return (
    <div style={{height: "100vh"}}>
      <div className="contact_section3">
        <h1 className="contact_header3"> Contact us</h1>


      <div className="contact_section3Content">

          <p className="contact_section3Text1">For all questions, please contact <span style={{textDecoration: "underline"}}>contact@syted.net</span></p>
      </div>

      </div>


    {/* FOOTER START */}
    <div className={classes.footerSection}>
      <div className={classes.footerOptionLogo}>Syted</div>

      {/* <Link style={{textDecoration: "none"}} to="/contact">
      <div className={classes.footerOption}>Contact us</div>
      </Link> */}

      {/* <Link style={{textDecoration: "none"}} to="/terms-and-conditions">
      <div className={classes.footerOption}>Terms</div>
      </Link>

      <Link style={{textDecoration: "none"}} to="/privacy-policy">
      <div className={classes.footerOption}>Privacy Policy</div>
      </Link> */}
      </div>
      {/* FOOTER END */}


      </div>
  )
}

export default Contact