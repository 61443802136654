import React, {useState} from 'react'
import { Container } from '@material-ui/core';

import PollSubmission from './PollSubmission.js';

const PollSubmit = () => {
  const [currentId, setCurrentId] = useState(0);

  return (
   <Container style={{margin: "15px auto 0px", padding: "0px"}}>
     <center>
      <PollSubmission currentId={currentId} setCurrentId={setCurrentId} />
      </center>
    </Container>
  
  )
}

export default PollSubmit