import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Modal, Box, Select, MenuItem, Snackbar } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom"

import useStyles from './styles';
import './styles.css';

import DeleteIcon from './assets/bin.png';

import { likeArgumentPost, setArgument, deleteArgument, flagArgumentPost } from '../../../actions/posts';

import { ReactComponent as WhiteStarIcon } from './SVG/white-star.svg';
import { ReactComponent as OrangeStarIcon } from './SVG/orange-star.svg';

import { ReactComponent as WhiteFlagIcon } from './SVG/white-flag.svg';
import { ReactComponent as OrangeFlagIcon } from './SVG/orange-flag.svg';

import Dots from './assets/dots.png';

const PollArgument = ({ post, setCurrentId }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));

  const dispatch = useDispatch();
  const [usersArgument, setUsersArguments] = useState(false);

  // const [liked, setLiked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [open, setOpen] = useState(false);

  const [argumentList, setArgumentList] = useState(post?.arguments);
  const [sortArgumentOption, setSortArgumentOption] = useState('popular');
  const sortedArguments = [...argumentList];

  const [likes, setLikes] = useState(post?.arguments?.likes);
  const userId = user?.user?.result?._id;
  const hasLikedPost = post?.arguments?.likes?.find((like) => like === userId);

  const [hasLiked, setHasLiked] = useState(false);


  const [hasFlagged, setHasFlagged] = useState(false);
  const hasFlaggedPost = post?.arguments?.flags?.find((flag) => flag === userId);
  const [flags, setFlags] = useState(false);

  // BOUNCE CSS STYLE:
  const [bounce, setBounce] = useState(true);
    
  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'just now';
    }
    const now = new Date();
    const diffMs = now - date;
  
    if (diffMs < 60000) {
      return 'just now';
    } else if (diffMs < 3600000) {
      const diffMins = Math.floor(diffMs / 60000);
      return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
    } else if (diffMs < 86400000) {
      const diffHrs = Math.floor(diffMs / 3600000);
      return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
    } else {
      const diffDays = Math.floor(diffMs / 86400000);
      return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
    }
  }

  // FUNCTIONALITY TO SORT FOR USER'S POSTS
  const handleSortUserArgument = () => {
    setUsersArguments(true);
  };

  const handleSort = (option) => {
    switch (option) {
      case 'popular':
        setSortArgumentOption('popular');
        break;
      case 'randomise':
        setSortArgumentOption('randomise');
        break;
      case 'newest':
        setSortArgumentOption('newest');
        break;
      case 'oldest':
        setSortArgumentOption('oldest');
        break;
      default:
        break;
    }
  };



  switch (sortArgumentOption) {
    case 'randomise':
      sortedArguments.sort(() => 0.5 - Math.random());
      break;
    case 'newest':
      sortedArguments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      break;
    case 'oldest':
      sortedArguments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      break;
    default:
      sortedArguments.sort((a, b) => b.likes.length - a.likes.length);
      break;
  }

// LIKE
  const handleLike = async (argumentId, argument) => {
    await dispatch(likeArgumentPost(post._id, argumentId));

    if (hasLikedPost) {
      setLikes(argument.likes.filter((id) => id !== userId));
      setHasLiked(false);
    } else {
      // setLikes([...argument.likes, userId]);
      setHasLiked(true);
    }
  };



  const Likes = ({ argument }) => {
    const userId = user?.result?._id;
    const [hasLiked, setHasLiked] = useState(argument.likes.includes(userId));
    const [liked, setLiked] = useState(false);

    const handleIconClick = () => {
      setLiked(!liked); // Toggle the liked state
      handleLike(argument._id); // Call the original handleLike function
    };
  
    const handleLike = async (argumentId) => {
      await dispatch(likeArgumentPost(post._id, argumentId));
  
      if (hasLiked) {
        setHasLiked(false);
        argument.likes = argument.likes.filter((like) => like !== userId);
      } else {
        setHasLiked(true);
        argument.likes.push(userId);
      }
    };
  
    return (
      <>
        {hasLiked ? (
          <div>
          <OrangeStarIcon onClick={() => handleLike(argument._id)} />
           {/* <p className={classes.starText}>Supported</p> */}
          </div>
        ) : (
          <>
          <div onClick={() => {
            handleIconClick();
            handleLike(argument._id);
          }}>
            {liked ? <OrangeStarIcon /> : <WhiteStarIcon />}
          </div>
        </>
        )}
      </>
    );
  };
  


// FLAG:
  const handleFlag = async (argumentId, argument) => {
    await dispatch(flagArgumentPost(post._id, argumentId));

    if (hasFlaggedPost) {
      setFlags(argument.flags.filter((id) => id !== userId));
      setHasFlagged(false);
    } else {
      setHasFlagged(true);
    }
  };


  const Flag = ({ argument }) => {
    const userId = user?.result?._id;
    const [hasFlagged, setHasFlagged] = useState(argument.flags.includes(userId));
    const [flagFront, setFlagFront] = useState(false);
  
    const handleFlaggedIconClick = () => {
      setFlagFront(!flagFront); // Toggle the liked state
      handleFlag(argument._id); // Call the original handleLike function
    };

    const handleFlag = async (argumentId) => {
      await dispatch(flagArgumentPost(post._id, argumentId));
  
      if (hasFlagged) {
        setHasFlagged(false);
        argument.flags = argument.flags.filter((flag) => flag !== userId);
      } else {
        setHasFlagged(true);
        argument.flags.push(userId);
      }
    };
  
    return (
      <>
        {hasFlagged ? (
          <div>
          <OrangeFlagIcon onClick={() => handleFlag(argument._id)} />
        </div>
        ) : (
          // <WhiteFlagIcon onClick={() => handleFlag(argument._id)} />
          <div onClick={() => {
            handleFlaggedIconClick();
            handleFlag(argument._id);
          }}>
            {flagFront ? <OrangeFlagIcon /> : <WhiteFlagIcon />}
          </div>
        )}
      </>
    );
  };
  


  const handleDeleteArgument = (argumentId) => {
    dispatch(deleteArgument(post._id, argumentId));
    setIsDeleted(true);
  };


// SNACKBAR:


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
};

const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

  return (
    // <div className={classes.selectArgumentContainer}>
    <div className="selectArgumentContainer">
    <div className={classes.selectArgumentContainerAlign}>
      <p className={classes.selectArgumentContainerHeader}> Positions </p>

        </div>

      <div className="selectArgumentDashboard">
          {/* <p className={classes.sortByText}>Sort:</p> */}
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort("popular")}>
          Popular
        </Button>
        {/* <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort("randomise")}>
          Randomise
        </Button> */}
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort('newest')}>
          Newest
        </Button>
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort('oldest')}>
          Oldest
        </Button>

        {post.arguments.some(argument => argument.creator === user?.result?._id) ? (
        <Button className={classes.addArgumentDashboardButton} onClick={() => handleSortUserArgument('usersArgument')}>
        My Position
        </Button>
          ) : (
        <NestedModal post={post} setCurrentId={setCurrentId} />
        )}
      </div>

      {post.arguments?.length > 0 ? (
     
      <div className={classes.commentsOuterContainer}>
        {/* {post.arguments?.length > 3 ? (
            <div className={`my-div ${bounce ? 'bounce' : ''}`}>
        <div className={classes.argumentScrollDownContainer}><p className={classes.argumentScrollDownText}>Scroll</p>
        </div>
   
            </div>
   ) : (<></>)} */}

      <div className="argumentSelectContainer">
      {usersArgument && (
  <div className="argumentSelectContainer">
    
    {post.arguments
  .filter((argument) => user?.result?._id === argument.creator)
  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  .map((argument, index) => (
    // code to render each argument

   
     <div className="argumentSelectStyle" key={index}>
    
     <div className="argumentStylePositionAndDate">
 <p className={classes.argumentStylePosition}>{argument.position}</p>
 <p className={classes.argumentStyleDateTextStyle} key={index}>{getTimeAgo(argument.createdAt)}</p>

 </div>
         {/* <p className={classes.argumentSelectStyleStatement}>{argument.statement}</p> */}
         <ReadMoreComponent argument={argument}/>
 
 
         <div className="argumentSelectButtonRightAlign">


      {user?.result?._id && (
        <>
        <Button size="small" onClick={() => handleFlag(argument._id)}>
            <Flag argument={argument} />
            {/* <p className={classes.flagText}>Flag</p> */}
        </Button>


        {user?.result?._id === argument.creator ? (
  <>
  <div onClick={handleClick} style={{ cursor: 'pointer' }}>
<Button
  onClick={(clickEvent) => {
    handleDeleteArgument(argument._id);
    clickEvent.target.style.display = 'none'; // hide the DeleteIcon image
  }}
>
  <img className={classes.resonateButtonBin} src={DeleteIcon} alt="delete" />
  {/* <p className={classes.starText}>Delete</p> */}
</Button>
</div>
</>

        ) : (
          <Button size="small" onClick={() => handleLike(argument._id)}>
            <Likes argument={argument} postId={post._id} />

          </Button>
        )}
        </>
        )}


         </div>
         <div style={{marginTop: "15px"}}></div>
       </div>
    ))}
  </div>
)}


  {sortedArguments
   .filter((argument) => argument.flagsTotal < 30)
  .map((argument, i) => (
    <div className="argumentSelectStyle" key={i}>
     <div className="argumentStylePositionAndDate">
<p className="argumentStylePosition">{argument.position}</p>
<p className={classes.argumentStyleDateTextStyle} key={i}>{getTimeAgo(argument.createdAt)}</p>

</div>
        {/* <p className={classes.argumentSelectStyleStatement}>{argument.statement}</p> */}
        <ReadMoreComponent argument={argument}/>


<div className="argumentSelectButtonRightAlign">



{user?.result?._id && (
        <>
        <Button size="small" onClick={() => handleFlag(argument._id)}>
            <Flag argument={argument} />
            {/* <p className={classes.flagText}>Flag</p> */}
        </Button>


        {user?.result?._id === argument.creator ? (
  <>
  <div onClick={handleClick} style={{ cursor: 'pointer' }}>
<Button
  onClick={(clickEvent) => {
    handleDeleteArgument(argument._id);
    clickEvent.target.style.display = 'none'; // hide the DeleteIcon image
  }}
>
  <img className={classes.resonateButtonBin} src={DeleteIcon} alt="delete" />
  {/* <p className={classes.starText}>Delete</p> */}
</Button>
</div>
</>

        ) : (
          <Button size="small" onClick={() => handleLike(argument._id)}>
            <Likes argument={argument} postId={post._id} />
          </Button>
        )}
        </>
        )}



        </div>
        <div style={{marginTop: "15px"}}></div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
        >
                <Alert onClose={handleClose} severity="success">
                  Deleted. Your argument will disapear once the page is refreshed.
                </Alert>
        </Snackbar>
      
    </div>
  ))}
</div>

</div>
      ) : (
      <>
        <img style={{margin: "5px auto", display: "flex", justifyContent: "center", width: "22px"}} src={Dots} alt=""/>
      </>
      )}
</div>
)
    }



export default PollArgument;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90vw",
  backgroundColor: "#E3E0DC",
  color: "#FFF",
  textAlign: "center",
  boxShadow: 24,
  padding: "10px 20px",
  borderRadius: "4px",
  overflow: "scroll",
  maxHeight: "80vh"
};


// ARGUMENT MODAL SUBMISSION:
function NestedModal({post}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const [newPosition, setNewPosition] = useState('');
  const [newPositions, setNewPositions] = useState(post?.newArguments);

  const [newArgument, setNewArgument] = useState('');
  const [newArguments, setNewArguments] = useState(post?.newArguments);

  const [newReference, setNewReference] = useState('');
  const [newReferences, setNewReferences] = useState(post?.newArguments);

  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();


// ADD ARGUMENT START

  const handleNewArgument = async () => {
    if (!user) {
      return; // Exit early if user is null
    }

    const newArgumentSubmit = {
      position: newPosition,
      statement: newArgument,
      // reference: newReference,
      creator: user?.result?._id,
      pseudoname: user?.result?.name,
      likes: 0,
      date: new Date(),
    };
  
    const updatedPost = await dispatch(setArgument(post._id, newArgumentSubmit));
    setNewPosition('');
    setNewPositions(updatedPost);
    setNewArgument('');
    setNewArguments(updatedPost);
    // setNewReference('');
    // setNewReferences(updatedPost);
  };
  // ADD ARGUMENT END

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div> 

{user?.result?._id === post.creator ? null :
  (user?.result?.email ? (
    <Button className={classes.addArgumentDashboardButton} onClick={handleOpen}>Add position</Button>
  ) : (
    <Link style={{ textDecoration: "none" }} to="/auth">
      <Button className={classes.addArgumentDashboardButton}>Add position</Button>
    </Link>
  ))
}




</div> 
           
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style, width: "90vw" }}>
        <div className={classes.addArgumentInnerContainer}>
        <div style={{ width: '100%' }}>
          <p className={classes.addArgumentPreHeaderPrompt}>What's your stance on the statement</p>
          <p className={classes.addArgumentHeader}>{post.statement}</p>
          <div className={classes.addArgumentAlign}>
          
          <p className="modalHeaderText">What's your position?</p>
          <Select
            fullWidth
            variant="standard"
            // label="What's your position?"
            required
            value={newPosition}
            onChange={(e) => setNewPosition(e.target.value)}
            style={{margin: "10px 0px"}}
            >
          <MenuItem value="agree">Agree</MenuItem>
          <MenuItem value="undecided">Undecided</MenuItem>
          <MenuItem value="disagree">Disagree</MenuItem>
        </Select>

    {/* The below div makes the TextField section scrollable when there's lots of text */}
        <div style={{ height: 'auto', overflow: 'auto', padding: "10px 0px 0px" }}>
          <p className="modalHeaderText">Add your argument below:</p>
          <TextField 
          fullWidth 
          multiline 
          // minRows={8} 
          variant="standard" 
          // label="Add your argument..." 
          value={newArgument} 
          onChange={(e) => setNewArgument(e.target.value)} 
          />
         </div>

          {/* <TextField style={{marginTop: "10px"}} fullWidth multiline minRows={1} variant="outlined" label="(Optional) add a reference..." value={newReference} onChange={(e) => setNewReference(e.target.value)} /> */}

          <Button style={{ backgroundImage: "radial-gradient(circle, #5C5A58, #423F3E, #3D3B3C)", fontSize: "0.8rem", fontWeight: 700, width: "100px", backgroundColor: "#3D3B3C", color: "#e3e0dc", margin: "20px auto", borderRadius: "4px", fontFamily: "Open Sans"  }} fullWidth disabled={!newArgument.length} variant="contained" onClick={handleNewArgument}>
            Submit
          </Button>
          </div>
        </div>
      </div>
        </Box>
      </Modal>
    </div>
  );
}



function ReadMoreComponent({ argument }) {
  const [showFullStatement, setShowFullStatement] = useState(false);
  const classes = useStyles();

  const handleReadMoreClick = () => {
    setShowFullStatement(!showFullStatement);
  };

  return (
    <div>
      <p onClick={handleReadMoreClick} className={classes.argumentSelectStyleStatement}>
        {showFullStatement ? argument.statement : argument.statement.substr(0, 250)}
        {argument.statement.length > 400 && (
          <span className="readMoreButtonArgument">
            {showFullStatement ? "...show less" : "...show more"}
          </span>
        )}
      </p>
    </div>
  );
}




{/* {user?.result?._id === argument.creator && (
  <>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
    <Button
      style={{ borderRadius: '20px 20px 0px 0px' }}
      color="primary"
      onClick={(clickEvent) => {
        handleDeleteArgument(argument._id);
        clickEvent.target.style.display = 'none'; // hide the DeleteIcon image
      }}
    >
      <img className={classes.resonateButtonBin} src={DeleteIcon} alt="delete" />
    </Button>
    </div>
  </>
)}
      {user?.result?._id && (
        <Button size="small" color="primary" 
            onClick={() => handleFlag(argument._id)}>
            <Flag argument={argument} />
            <p className={classes.flagText}>Flag</p>
        </Button>
      )}
        {user?.result?._id && (
        <Button size="small" color="primary"  
          onClick={() => handleLike(argument._id)}>
         <Likes argument={argument} />
         <p className={classes.starText}>Support</p>
        </Button>
        )} */}
