import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';

import useStyles from './styles';
import PollViewMode from './PollViewMode/PollViewMode';
import ScrollToTopButton from './ScrollToTopButton';

const PollsPopular = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();


// LIMITED ADDED (SPLICE_)
const filteredPosts = [...posts].sort((a, b) => (b.likesTotal - b.dislikesTotal) - (a.likesTotal - a.dislikesTotal))
    .map((post) => ({ ...post, score: post.likesTotal - post.dislikesTotal }))
    // .filter((post) => post.dislikesTotal <= 10) // Add this line to filter out posts with more than 10 dislikes


    .filter((post) => {
      if (post.dislikesTotal > 10) {
        return post.dislikesTotal <= post.likesTotal * 0.8;
      }
      return true; // Skip the filter if dislikesTotal is 10 or less
    })

    .filter((post) => post.arguments.length > 1) // New filter: Only include posts with more than 1 argument

    .slice(0, 20)
    .filter((post) => post.exclusive === false)
    .map((post, index) => (
      <div className={classes.postGrid}>
        <PollViewMode post={post} setCurrentId={setCurrentId} rank={index + 1} />
      </div>
    ));



  // const filteredPosts = [...posts].sort((a, b) => (b.likesTotal - b.dislikesTotal) - (a.likesTotal - a.dislikesTotal))
  //   .map((post) => ({ ...post, score: post.likesTotal - post.dislikesTotal }))
  //   .map((post) => (
  //     <div className={classes.postGrid}>
  //       <PollViewMode post={post} setCurrentId={setCurrentId} />
  //     </div>
  //   ));

  if(!posts.length && !isLoading) return (<div className={classes.errorText}>Oops! No results, please double check you have searched correctly</div>);

  return (
    isLoading ?
    <div style={{height: "100vh"}}>
       <CircularProgress style={{ 
          color: '#3D3B3C', 
          margin: "15% 50%" 
          }} size="1.5rem" />
    </div>
      :
      !posts?.length ?
      <div style={{height: "100vh"}}>
      <CircularProgress style={{ 
        color: '#3D3B3C', 
        margin: "15% 50%" 
        }} size="1.5rem" />
      </div>
        :
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
          <Grid style={{justifyContent: "center"}} className={classes.container} container alignItems="stretch" spacing={4}>
            {filteredPosts}
          </Grid>
             <ScrollToTopButton/>   
        </div>
  );
}

export default PollsPopular;
