import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  appBarSearchCollection: {
    margin: '20px 0px 40px',
    padding: '0px 8px 0px 4px',
    maxWidth: "800px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: "20px"
  },
  appBarSearchInput: {
    margin: "8px 5px",
    width: "100%",
    height: "30px",
    borderRadius: "15px 0px 0px 15px",
    border: "2.5px solid #FE6E3B",
    padding: "0px 12px"
  }, 

  linkToSubmissionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width:"100%",
    // marginBottom: "20px"
  },

  linkToSubmissionContainer1Text: {
    fontFamily: "Urbanist",
    fontWeight: 700,
    fontSize: "25px",
    color: "#fd876b",
  },

  linkToSubmissionContainer1: {
    marginRight: "20px" 
  },

  submissionButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    height: "30px",
    margin: "10px auto",
    // backgroundColor: "#3D3B3C",
    padding: "25px 25px",
    borderRadius: "5px",
    fontSize: "16px",
    color: "rgba(61, 59, 60, 0.8)",
    fontFamily: "Open Sans",
    fontWeight: 500,
    textTransform: "none",
    border: "2px solid rgba(61, 59, 60, 0.6)",
    boxShadow: "0px 4px 5px 2px rgba(61,59,60,0.3)",
  },
  
  submissionButtonText: {
    padding: "0px",
    margin: "0px 10px",
  },


  pollSubmissionContainer: {
    margin: "20px 0px 40px",
    justifyContent: "center"
  },


  pagination: {
    borderRadius: 4,
    marginTop: '1rem',
    padding: '16px',
  },

  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    borderRadius: "20px",
    // marginBottom: "80px",
    border: "7px solid #FE876B",
    maxWidth: "40vw",
    margin: "50px auto 80px",
    backgroundColor: "#FE876B",
  },

  signInFlag: {
    textAlign: "center",
    fontSize: "23px",
    // color: "#FE876B",
    color: "#fff",
    fontFamily: "Urbanist",
    fontWeight: 500
  },


  //AUTH BACKGROUND:

    backgroundImageStyle: {
        display: "flex",
        margin: "0px auto",
        opacity: "0.8",
        maxWidth: "100%"
    },

    authBackgroundImage: {
        position: 'relative',
        display: "flex",
        margin: "0px auto",
        height: "100vh"
    },


    newHereButton: {
      // backgroundColor: "#FE876B",
      color: "#3D3B3C",
      fontFamily: "Open Sans",
      fontSize: "12px",
      padding: "5px 10px",
      fontWeight: 700,
      letterSpacing: "0.2px",
      marginBottom: "40px",
      cursor: "pointer",
    },



    newHereTourContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },

    newHereImageStyle: {
      maxWidth: "380px"
    },



    newHereSubTitle: {
      // color: "#fff",
      fontSize: "19px",
      fontWeight: 700,
      margin: "20px 0px 10px",
      color: "#FE876B",
      textAlign: "center"
    },

    newHereSmallTitle: {
      // color: "#fff",
      fontSize: "20px",
      fontWeight: 700,
      color: "#FE876B",
      textAlign: "center"
    },

    newHereBorder: {
      borderBottom: "1.5px solid #FFF",
      margin: "20px 0px 10px"
    },

    newHereIntrcutions: {
      // color: "#fff",
      fontSize: "17px",
      fontWeight: 500,
      margin: "5px 10px 0px",
      color: "#FE876B",
      textAlign: "center"
    },

    newHereIntrcutions2: {
      // color: "#fff",
      fontSize: "18px",
      fontWeight: 500,
      margin: "3px 10px 10px",
      color: "#FE876B",
      textAlign: "center"
    },
}));