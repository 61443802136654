import React, { useState } from 'react';
import { Button, Snackbar } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';

import useStyles from './styles';
import './styles.css';

// import ScrollDown from '../../assets/scrollDown.png';

import { useDispatch } from 'react-redux';

const PollViewModeArgument = ({ post, setCurrentId }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));

  const dispatch = useDispatch();
  const [usersArgument, setUsersArguments] = useState(false);

  const [open, setOpen] = useState(false);

  const [argumentList, setArgumentList] = useState(post?.arguments);
  const [sortArgumentOption, setSortArgumentOption] = useState('popular');
  const sortedArguments = [...argumentList];


    
  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'just now';
    }
    const now = new Date();
    const diffMs = now - date;
  
    if (diffMs < 60000) {
      return 'just now';
    } else if (diffMs < 3600000) {
      const diffMins = Math.floor(diffMs / 60000);
      return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
    } else if (diffMs < 86400000) {
      const diffHrs = Math.floor(diffMs / 3600000);
      return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
    } else {
      const diffDays = Math.floor(diffMs / 86400000);
      return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
    }
  }

  // FUNCTIONALITY TO SORT FOR USER'S POSTS
  const handleSortUserArgument = () => {
    setUsersArguments(true);
  };

  const handleSort = (option) => {
    switch (option) {
      case 'popular':
        setSortArgumentOption('popular');
        break;
      case 'randomise':
        setSortArgumentOption('randomise');
        break;
      case 'newest':
        setSortArgumentOption('newest');
        break;
      case 'oldest':
        setSortArgumentOption('oldest');
        break;
      default:
        break;
    }
  };
  

  switch (sortArgumentOption) {
    case 'randomise':
      sortedArguments.sort(() => 0.5 - Math.random());
      break;
    case 'newest':
      sortedArguments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      break;
    case 'oldest':
      sortedArguments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      break;
    default:
      sortedArguments.sort((a, b) => b.likes.length - a.likes.length);
      break;
  }
  


// SNACKBAR:

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};



  return (
    // <div className={classes.selectArgumentContainer}>
  <div className="selectArgumentContainer">
       <div className={classes.selectArgumentContainerAlign}>
    <p className={classes.selectArgumentContainerHeader}> Positions </p>

    </div>
      <div className="selectArgumentDashboard">
          {/* <p className={classes.sortByText}>Sort:</p> */}
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort("popular")}>
          Popular
        </Button>
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort("randomise")}>
          Random
        </Button>
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort('newest')}>
          Newest
        </Button>
        <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSort('oldest')}>
          Oldest
        </Button>



{post.arguments.some(argument => argument.creator === user?.result?._id) && (

  <Button className={classes.selectArgumentDashboardButton} onClick={() => handleSortUserArgument('usersArgument')}>
  My Position
</Button>
  
)}

      </div>
{/* 
      <div className={`my-div ${bounce ? 'bounce' : ''}`}>
      <div className={classes.argumentScrollDownContainer}><p className={classes.argumentScrollDownText}>Scroll</p>
        </div>
            </div> */}

      {post.arguments?.length > 0 ? (
     
      <div className={classes.commentsOuterContainer}>
      <div className={classes.argumentSelectContainer}>
      {usersArgument && (
  <div className={classes.argumentSelectContainer}>
    
    {post.arguments
  .filter((argument) => user?.result?._id === argument.creator)
  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  .map((argument, index) => (

   
     <div className="argumentSelectStyle" key={index}>
    
     <div className="argumentStylePositionAndDate">
 <p className={classes.argumentStylePosition}>{argument.position}</p>
 <p className={classes.argumentStyleDateTextStyle2} key={index}>{getTimeAgo(argument.createdAt)}</p>
 </div>
         <p className={classes.argumentSelectStyleStatement}>{argument.statement}</p>
 
 
         <div className="argumentSelectButtonRightAlign">
         </div>

 
 
         <div style={{marginTop: "15px"}}></div>
       </div>
    ))}
  
  </div>
)}

  {sortedArguments
   .filter((argument) => argument.flagsTotal < 30)
  .map((argument, i) => (
    <div className="argumentSelectStyle" key={i}>
     <div className="argumentStylePositionAndDate">
<p className="argumentStylePosition">{argument.position}</p>
<p className={classes.argumentStyleDateTextStyle2} key={i}>{getTimeAgo(argument.createdAt)}</p>
</div>
        <p className={classes.argumentSelectStyleStatement}>{argument.statement}</p>


        <div className="argumentSelectButtonRightAlign">
        </div>
 

        <div style={{marginTop: "15px"}}></div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Deleted. Your position will disapear once the page is refreshed.
                </Alert>
        </Snackbar>
      
    </div>
  ))}
</div>

</div>

      ) : (
      <>
        <p className={classes.noArgumentsNotification}>There were no positions for this post</p>
      </>
      )}
</div>
)
    }

export default PollViewModeArgument;

