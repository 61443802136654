import React, {useState} from 'react'
import PollSubmit from './PollSubmit'

const Submit = () => {
    const [currentId, setCurrentId] = useState(0);

  return (
    <div>
              <PollSubmit currentId={currentId} setCurrentId={setCurrentId}/>
    </div>
  )
}

export default Submit