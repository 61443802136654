import { FETCH_ALL, FETCH_POST, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, LIKE, DISLIKE, START_LOADING, END_LOADING, COMMENT, LIKE_ARGUMENT, FLAG_ARGUMENT, LIKE_COMMENT, ADD_ARGUMENT, FLAG, FLAG_COMMENT, DELETE_ARGUMENT, DELETE_COMMENT } from '../constants/actionTypes';
import * as api from '../api/index.js';


// ACTION CREATORS --> FUNCTIONS


export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({type: START_LOADING})
    const { data } = await api.fetchPost(id);


    dispatch({ type: FETCH_POST, payload: data });
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error.message);
  }
};


export const getPolls = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchPolls(page);

    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};


// export const getPolls = (page) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });
//     const { data: { data, currentPage, numberOfPages } } = await api.fetchPolls(page);

//     dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error);
//   }
// };


 



  export const getPostsBySearch = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchPostsBySearch(searchQuery);
  
      dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
      dispatch({ type: END_LOADING });

    } catch (error) {
      console.log(error.message + "error in the get post by search");
    }
  };



  export const createPoll = (post, history) => async (dispatch) => {
    try {
      dispatch({type: START_LOADING})
      const { data } = await api.createPoll(post);
  
      dispatch({ type: CREATE, payload: data });
      history.push(`/explore/${data._id}`);
    } catch (error) {
      console.log(error + "error with create POST action") ;
    }
  };





  export const updatePost = (id, post) => async (dispatch) => {
    try {
      const { data } = await api.updatePost(id, post);
  
      dispatch({ type: UPDATE, payload: data });
    } catch (error) {
      console.log(error.message);
    }
  };


  export const deletePost = (id) => async (dispatch) => {
    try {
      await api.deletePost(id);
  
      dispatch({ type: DELETE, payload: id });
    } catch (error) {
      console.log(error);
    }
  };

  export const likePost = (id) => async (dispatch) => {
    try {
      const { data } = await api.likePost(id);
  
      dispatch({ type: LIKE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const flagPost = (id) => async (dispatch) => {
    try {
      const { data } = await api.flagPost(id);
  
      dispatch({ type: FLAG, payload: data });
    } catch (error) {
      console.log(error);
    }
  };


  export const dislikePost = (id) => async (dispatch) => {
    try {
      const { data } = await api.dislikePost(id);
  
      dispatch({ type: DISLIKE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
  

  export const likeArgumentPost = (id, argumentId) => async (dispatch) => {
    try {

      const { data } = await api.likeArgumentPost(id, argumentId);
      dispatch({ type: LIKE_ARGUMENT, payload: data });
    } catch (error) {
      console.log(`Error while liking argument: ${error.message}`);
      console.log("postId:", id);
      console.log("argumentId:", argumentId);
    }
  };

  export const flagArgumentPost = (id, argumentId) => async (dispatch) => {
    try {

      const { data } = await api.flagArgumentPost(id, argumentId);
      dispatch({ type: FLAG_ARGUMENT, payload: data });
    } catch (error) {
      console.log(`Error while flagging argument: ${error.message}`);
      console.log("postId:", id);
      console.log("argumentId:", argumentId);
    }
  };



  
  export const deleteArgument = (postId, argumentId) => async (dispatch) => {
    try {
      const { data } = await api.deleteArgument(postId, argumentId);
      dispatch({ type: DELETE_ARGUMENT, payload: data });
      // console.log("postId SUCCESS:", postId);
      // console.log("argumentId SUCCESS:", argumentId);
    } catch (error) {
      console.log(`Error while deleting argument: ${error.message}`);
      console.log("postId FAIL:", postId);
      console.log("argumentId FAIL:", argumentId);
    }
  };




  export const likeCommentPost = (id, commentId) => async (dispatch) => {
    try {

      const { data } = await api.likeCommentPost(id, commentId);
      dispatch({ type: LIKE_COMMENT, payload: data });
    } catch (error) {
      console.log(`Error while liking argument: ${error.message}`);
      console.log("postId:", id);
      console.log("commentId:", commentId);
    }
  };




  export const flagCommentPost = (id, commentId) => async (dispatch) => {
    try {

      const { data } = await api.flagCommentPost(id, commentId);
      dispatch({ type: FLAG_COMMENT, payload: data });
      // console.log("success with flagging post:" + id + " " + commentId)
    } catch (error) {
      console.log(`Error while flagging argument: ${error.message}`);
      console.log("postId:", id);
      console.log("commentId:", commentId);
    }
  };




  export const deleteComment = (postId, commentId) => async (dispatch) => {
    try {
      const { data } = await api.deleteComment(postId, commentId);
      dispatch({ type: DELETE_COMMENT, payload: data });
      
   
      // console.log("postId SUCCESS:", postId);
      // console.log("commentId SUCCESS:", commentId);
    } catch (error) {
      console.log(`Error while deleting comment: ${error.message}`);
      console.log("postId FAIL:", postId);
      console.log("commentId FAIL:", commentId);
    }
  };

    


  export const commentPost = (id, newComment) => async (dispatch) => {
    try {
      const { data } = await api.comment(id, newComment);
  
      // RETRIEVES the comments data -- to display
      dispatch({ type: COMMENT, payload: data });
  
      return data.comments;
    } catch (error) {
      console.log("error in the commentPost action");
      console.log(id)
    }
  };




  
  


// NEW ARUGMENT
  export const setArgument = (id, newArgumentSubmit) => async (dispatch) => {
    try {
      const { data } = await api.newArgument(id, newArgumentSubmit);
  
      // RETRIEVES the comments data -- to display
      dispatch({ type: ADD_ARGUMENT, payload: data });
  
      return data.newArgument;
    } catch (error) {
      console.log("error in the commentPost action");
      console.log(id)
    }
  };
  



