import React, { useState, useRef, useEffect } from 'react';
import { Button, Snackbar } from '@material-ui/core/';
import { useDispatch } from 'react-redux';

import { deleteComment } from '../../../actions/posts';
import MuiAlert from '@material-ui/lab/Alert';
import useStyles from './styles';

import DeleteIcon from './assets/bin.png';
import { ReactComponent as OrangeHeartIcon } from './SVG/orange-heart.svg';


const PollViewModeCommentSection = ({ post }) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const [comments, setComments] = useState(post?.comments);
  const classes = useStyles();
  const commentsRef = useRef();
  const [open, setOpen] = useState(false);


//   DELETED:
const [isDeleted, setIsDeleted] = useState(false);


  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'just now';
    }
    const now = new Date();
    const diffMs = now - date;
  
    if (diffMs < 60000) {
      return 'just now';
    } else if (diffMs < 3600000) {
      const diffMins = Math.floor(diffMs / 60000);
      return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
    } else if (diffMs < 86400000) {
      const diffHrs = Math.floor(diffMs / 3600000);
      return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
    } else {
      const diffDays = Math.floor(diffMs / 86400000);
      return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
    }
  }

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(post._id, commentId));
    setIsDeleted(true);
  };

  useEffect(() => {
    if (isDeleted) {
      setComments(post.comments);
      setIsDeleted(false);
    }
  }, [isDeleted, post.comments]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="bottomSide">
<div className={classes.commentsContainer}>
<div className={classes.addCommentsInnerContainer}>
<p className={classes.selectArgumentContainerHeader}> Discussion </p>
</div>
<div className="selectCommentDashboard">
</div>
</div>
{post?.arguments?.length > 0 ? (
<div className={classes.addCommentsContainer}>
{post.comments.sort((a, b) => b.likesTotal - a.likesTotal).map((comment, index) => (
  <>

  <div className={classes.argumentCommentStyle} key={index}>
  <div className={classes.arguemntStyleAlign}>
  <p className={classes.commentUsernameStyle}>{comment.pseudoname}</p>
  <p className={classes.commentDateTextStyle} key={index}>{getTimeAgo(comment.createdAt)}</p>
  </div>
  <p className={classes.commentTextStyle} key={index}>{comment.comment}</p>

  <div ref={commentsRef} />

  <div className="argumentSelectButtonRightAlign">
        {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <OrangeHeartIcon/>
        </div> */}

{user?.result?._id === comment.creator && (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
    <Button
      style={{ borderRadius: '20px 20px 0px 0px' }}
      color="primary"
      onClick={(clickEvent) => {
        handleDeleteComment(comment._id);
        clickEvent.target.style.display = 'none'; // hide the DeleteIcon image
      }}
    >
      <img className={classes.resonateButtonBin} src={DeleteIcon} alt="delete" />
    </Button>
    </div>
)}


</div>
<Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={{ '& .MuiPaper-root': { backgroundColor: '#3D3B3C' } }}
    >
      <Alert onClose={handleClose} severity="success">
        Comment successfully deleted
      </Alert>
    </Snackbar>
  </div>


</>

))}

        <div ref={commentsRef} />
        </div>

        ) : (
          <div className={classes.addCommentsContainer}>
        <p className={classes.addCommentsNoneYet}>There were no comments for this post</p>
        </div>
        
        )}
        <div className={classes.viewModeCommentNote}>...</div>
    </div>
  );
};

export default PollViewModeCommentSection;