import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';

import Topic from './Topic/Topic';
import useStyles from './styles';
import ScrollToTopButton from './ScrollToTopButton';

const Topics = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();

  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  const filteredPosts = posts
    .filter((post) => {
      const postDate = new Date(post.createdAt);
      return postDate >= sevenDaysAgo && postDate <= currentDate;
    })
    .map((post) => ({ ...post, score: post.likesTotal - post.dislikesTotal }))
    .filter((post) => {
      if (post.dislikesTotal > 10) {
        return post.dislikesTotal <= post.likesTotal * 0.8;
      }
      return true;
    })
    .filter((post) => post.exclusive === false || !post.hasOwnProperty('exclusive'))
    .sort((a, b) => Math.abs(b.likesTotal - b.dislikesTotal) - Math.abs(a.likesTotal - a.dislikesTotal))
    .map((post) => (
      <div key={post._id} className={classes.postGrid}>
        <Topic post={post} setCurrentId={setCurrentId} />
      </div>
    ));

  if (isLoading) {
    return (
      <center>
        <CircularProgress style={{ 
          color: '#3D3B3C', 
          margin: "10% 50%" 
          }} size="1.5rem" />
      </center>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
      <Grid style={{ justifyContent: "center" }} className={classes.container} container alignItems="stretch" spacing={4}>
        {filteredPosts.length > 0 ? filteredPosts : <div className="noPostsStyle">No recent posts available</div>}
      </Grid>
    </div>
  );
};

export default Topics;
